import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, mergeMap, tap, throwError } from 'rxjs';
import { Filesets } from '../../models/filesets';
import { FilesetsActions } from './filesets.actions';
import { Files } from '../../models/files';

export class FilesetsStateModel {
  loading: boolean | undefined;
  error: any;
  filesetList: Filesets[] | undefined;
  filesetSelected: Filesets | undefined | null;
  next: any;
  previous: any;
  fileList: Files[] | undefined
  nextFiles: any
}

const defaults: FilesetsStateModel = {
  loading: false,
  error: null,
  filesetList: [],
  filesetSelected: null,
  next: null,
  previous: null,
  fileList: [],
  nextFiles: null
};

@State<FilesetsStateModel>({
  name: 'filesets',
  defaults,
})
@Injectable()
export class FilesetsState {
  @Selector()
  static isLoading(state: FilesetsStateModel) {
    return state.loading;
  }

  @Selector()
  static getFilesetsError(state: FilesetsStateModel) {
    return state.error;
  }

  @Selector()
  static getFilesetList(state: FilesetsStateModel) {
    return state.filesetList;
  }

  @Selector()
  static getFilesetSelected(state: FilesetsStateModel) {
    return state.filesetSelected;
  }

  @Selector()
  static getLoadNext(state: FilesetsStateModel) {
    return state.next;
  }

  @Selector()
  static getLoadPrevious(state: FilesetsStateModel) {
    return state.previous;
  }

  @Selector()
  static getFileList(state: FilesetsStateModel) { return state.fileList }

  @Selector()
  static getLoadNextFiles(state: FilesetsStateModel) { return state.nextFiles }

  constructor(private http: HttpClient) { }

  @Action(FilesetsActions.LoadFilesets)
  loadFilesets(
    ctx: StateContext<FilesetsStateModel>,
    { uri }: FilesetsActions.LoadFilesets
  ) {
    ctx.patchState({ loading: true, error: null });
    return this.http.get(uri).pipe(
      tap((resp: any) => {
        ctx.patchState({
          loading: false,
          filesetList: resp.entities,
          next:
            resp && resp.resources.next
              ? resp.resources.next.uri.split('?')[1]
              : null,
          previous:
            resp && resp.resources.previous
              ? resp.resources.previous.uri.split('?')[1]
              : null,
        });
      }),
      catchError((err) => {
        ctx.patchState({
          loading: false,
          filesetList: [],
          next: null,
          previous: null,
          error: err.error.errors
            ? err.error.errors.toString()
            : err.error.message,
        });
        return throwError(() => err);
      })
    );
  }

  @Action(FilesetsActions.Reset)
  reset(ctx: StateContext<FilesetsStateModel>, { }: FilesetsActions.Reset) {
    ctx.patchState({ filesetSelected: null, error: null, fileList: [], nextFiles: null });
  }

  @Action(FilesetsActions.SelectedFileset)
  selectedFileset(
    ctx: StateContext<FilesetsStateModel>,
    { fileset }: FilesetsActions.SelectedFileset
  ) {
    ctx.patchState({ filesetSelected: fileset });
  }

  @Action(FilesetsActions.CreateFilesetIntent)
  createFilesetIntent(
    ctx: StateContext<FilesetsStateModel>,
    { payload, uri }: FilesetsActions.CreateFilesetIntent
  ) {
    ctx.patchState({ loading: true, error: null });
    return this.http.post(uri, payload).pipe(
      tap((result: any) => {
        ctx.patchState({ loading: false });
      }),
      mergeMap(() => {
        return ctx.dispatch(new FilesetsActions.CreateFilesetIntentSuccess());
      }),
      catchError((err) => {
        ctx.patchState({
          loading: false,
          error: err.error.errors
            ? err.error.errors.toString()
            : err.error.message,
        });
        return ctx.dispatch(new FilesetsActions.CreateFilesetIntentFailed());
      })
    );
  }

  @Action(FilesetsActions.EditFilesetIntent)
  editFilesetIntent(ctx: StateContext<FilesetsStateModel>, { payload, uri }: FilesetsActions.EditFilesetIntent) {
    ctx.patchState({ loading: true, error: null });
    return this.http.patch(uri, payload).pipe(tap(() => {
      ctx.patchState({ loading: false })
    }),
      mergeMap(() => {
        return ctx.dispatch(new FilesetsActions.EditFilesetIntentSuccess())
      }),
      catchError(err => {
        ctx.patchState({ loading: false, error: err.error.errors ? err.error.errors.toString() : err.error.message });
        return ctx.dispatch(new FilesetsActions.EditFilesetIntentFailed())
      })
    );
  }

  @Action(FilesetsActions.DeleteFilesetIntent)
  deleteFilesetIntent(
    ctx: StateContext<FilesetsStateModel>,
    { uri }: FilesetsActions.DeleteFilesetIntent
  ) {
    ctx.patchState({ loading: true, error: null });
    return this.http.delete(uri).pipe(
      tap(() => {
        ctx.patchState({ loading: false });
        return ctx.dispatch(new FilesetsActions.DeleteFilesetIntentSuccess());
      }),
      catchError((err) => {
        ctx.patchState({
          loading: false,
          error: err.error.errors
            ? err.error.errors.toString()
            : err.error.message,
        });
        return ctx.dispatch(new FilesetsActions.DeleteFilesetIntentFailed());
      })
    );
  }

  @Action(FilesetsActions.LoadFiles)
  loadFunctions(ctx: StateContext<FilesetsStateModel>, { uri }: FilesetsActions.LoadFiles) {
    ctx.patchState({ loading: true });
    return this.http.get(uri).pipe(tap((resp: any) => {
      ctx.patchState({
        loading: false,
        fileList: resp.entities,
        nextFiles: (resp && resp.resources.next) ? resp.resources.next.uri.split("?")[1] : null
      });
    }),
      catchError((err) => {
        ctx.patchState({ loading: false });
        return throwError(() => err);
      }));
  }
}
