import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Action, Selector, State, StateContext } from "@ngxs/store"
import { UsersActions } from "./users.actions"
import { catchError, mergeMap, tap, throwError } from "rxjs"
import { Users } from "../../models/users"
import { Account } from "../../models/account"

export class UsersStateModel {
    loading: boolean | undefined
    error: any
    userList: Users[] | undefined
    userSelected: Users | undefined | null
    next: any
    previous: any
    accountList: Account[] | undefined;
    nextAccounts: any;
}

const defaults: UsersStateModel = {
    loading: false,
    error: null,
    userList: [],
    userSelected: null,
    next: null,
    previous: null,
    accountList: [],
    nextAccounts: null,
}

@State<UsersStateModel>({
    name: 'users',
    defaults
})
@Injectable()
export class UsersState {

    @Selector()
    static isLoading(state: UsersStateModel) { return state.loading; }

    @Selector()
    static getUsersError(state: UsersStateModel) { return state.error; }

    @Selector()
    static getUserList(state: UsersStateModel) { return state.userList }

    @Selector()
    static getUserSelected(state: UsersStateModel) { return state.userSelected }

    @Selector()
    static getLoadNext(state: UsersStateModel) {
        return state.next;
    }

    @Selector()
    static getLoadPrevious(state: UsersStateModel) {
        return state.previous;
    }

    @Selector()
    static getAccountList(state: UsersStateModel) {
        return state.accountList;
    }

    @Selector()
    static getLoadNextAccounts(state: UsersStateModel) {
        return state.nextAccounts;
    }

    constructor(private http: HttpClient) { }

    @Action(UsersActions.LoadUsers)
    loadUsers(ctx: StateContext<UsersStateModel>, { uri }: UsersActions.LoadUsers) {
        ctx.patchState({ loading: true });
        return this.http.get(uri).pipe(tap((resp: any) => {
            ctx.patchState({
                loading: false,
                userList: resp.entities,
                next:
                    resp && resp.resources.next
                        ? resp.resources.next.uri.split('?')[1]
                        : null,
                previous:
                    resp && resp.resources.previous
                        ? resp.resources.previous.uri.split('?')[1]
                        : null,
            });
        }),
            catchError((err) => {
                ctx.patchState({ loading: false, userList: [], next: null, previous: null });
                return throwError(() => err);
            }));
    }

    @Action(UsersActions.SelectedUser)
    selectUser(ctx: StateContext<UsersStateModel>, { user }: UsersActions.SelectedUser) {
        ctx.patchState({ userSelected: user });
    }

    @Action(UsersActions.LoadAccounts)
    loadAccounts(
        ctx: StateContext<UsersStateModel>,
        { uri }: UsersActions.LoadAccounts
    ) {
        ctx.patchState({ loading: true });
        return this.http.get(uri).pipe(
            tap((resp: any) => {
                ctx.patchState({
                    loading: false,
                    accountList: resp.entities,
                    nextAccounts:
                        resp && resp.resources.next
                            ? resp.resources.next.uri.split('?')[1]
                            : null,
                });
            }),
            catchError((err) => {
                ctx.patchState({ loading: false });
                return throwError(() => err);
            })
        );
    }

    @Action(UsersActions.CreateUserIntent)
    createUserIntent(ctx: StateContext<UsersStateModel>, { payload, uri }: UsersActions.CreateUserIntent) {
        ctx.patchState({ loading: true, error: null });
        return this.http.post(uri, payload).pipe(tap((result: any) => {
            ctx.patchState({ loading: false })
        }),
            mergeMap(() => {
                return ctx.dispatch(new UsersActions.CreateUserIntentSuccess())
            }),
            catchError(err => {
                ctx.patchState({ loading: false, error: err.error.errors ? err.error.errors.toString() : err.error.message });
                return ctx.dispatch(new UsersActions.CreateUserIntentFailed())
            })
        );
    }

    @Action(UsersActions.EditUserIntent)
    editUserIntent(ctx: StateContext<UsersStateModel>, { payload, uri }: UsersActions.EditUserIntent) {
        ctx.patchState({ loading: true, error: null });
        return this.http.put(uri, payload).pipe(tap(() => {
            ctx.patchState({ loading: false })
        }),
            mergeMap(() => {
                return ctx.dispatch(new UsersActions.EditUserIntentSuccess())
            }),
            catchError(err => {
                ctx.patchState({ loading: false, error: err.error.errors ? err.error.errors.toString() : err.error.message });
                return ctx.dispatch(new UsersActions.EditUserIntentFailed())
            })
        );
    }

    @Action(UsersActions.DeleteUserIntent)
    deleteUserIntent(
        ctx: StateContext<UsersStateModel>,
        { uri }: UsersActions.DeleteUserIntent
    ) {
        ctx.patchState({ loading: true, error: null });
        return this.http.delete(uri).pipe(
            tap(() => {
                ctx.patchState({ loading: false });
                return ctx.dispatch(
                    new UsersActions.DeleteUserIntentSuccess()
                );
            }),
            catchError((err) => {
                ctx.patchState({
                    loading: false,
                    error: err.error.errors
                        ? err.error.errors.toString()
                        : err.error.message,
                });
                return ctx.dispatch(
                    new UsersActions.DeleteUserIntentFailed()
                );
            })
        );
    }

    @Action(UsersActions.SentUserInvitation)
    sentUserInvitation(ctx: StateContext<UsersStateModel>, { payload, uri }: UsersActions.SentUserInvitation) {
        ctx.patchState({ loading: true, error: null });
        return this.http.post(uri, payload).pipe(tap((result: any) => {
            ctx.patchState({ loading: false })
        }),
            mergeMap(() => {
                return ctx.dispatch(new UsersActions.SentUserInvitationSuccess())
            }),
            catchError(err => {
                ctx.patchState({ loading: false, error: err.error.errors ? err.error.errors.toString() : err.error.message });
                return ctx.dispatch(new UsersActions.SentUserInvitationFailed())
            })
        );
    }


}