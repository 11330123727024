import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { catchError, Observable, throwError } from "rxjs";
import { AuthState } from "../../store/auth/auth.state";
import { OrganizationsState } from "../../store/organizations/organizations.state";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { MSG } from "../mesages/messages";
import { AuthActions } from "../../store/auth/auth.actions";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private store: Store, private router: Router, private toastr: ToastrService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const session = this.store.selectSnapshot<any>(AuthState.getSession);
        const org = this.store.selectSnapshot<any>(OrganizationsState.getSelectedOrganization);
        const orgFile = this.store.selectSnapshot<any>(OrganizationsState.getOrganizationHeader);
        if (session) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${session.entity.accessToken}`,
                    'X-LINKPAL-ORGANIZATION': orgFile ? orgFile.entity.uuid : org ? org.entity.uuid : ''
                }
            });
        }
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    this.handleSessionTimeout();
                }
                return throwError(() => error);
            })
        );
    }

    handleSessionTimeout() {
        this.store.dispatch(new AuthActions.ResetDefaultValue());
        this.toastr.error(MSG.sessionTimeOut);
        this.router.navigate(['/login']);
    }
}