const ACTION_SCOPE = '[Users]';

export namespace UsersActions {
    export class LoadUsers {
        static readonly type = `${ACTION_SCOPE} List User`
        constructor(public uri: string) { }
    }

    export class SelectedUser {
        static readonly type = `${ACTION_SCOPE} Selected User`
        constructor(public user: any) { }
    }

    export class LoadAccounts {
        static readonly type = `${ACTION_SCOPE} List Account`
        constructor(public uri: string) { }
    }

    export class CreateUserIntent {
        static readonly type = `${ACTION_SCOPE} Create User Intent`;
        constructor(public payload: any, public uri: string) { }
    }

    export class CreateUserIntentSuccess {
        static readonly type = `${ACTION_SCOPE} Create User Intent Success`;
        constructor() { }
    }

    export class CreateUserIntentFailed {
        static readonly type = `${ACTION_SCOPE} Create User Intent Failed`;
        constructor() { }
    }

    export class EditUserIntent {
        static readonly type = `${ACTION_SCOPE} Edit User Intent`;
        constructor(public payload: any, public uri: string) { }
    }

    export class EditUserIntentSuccess {
        static readonly type = `${ACTION_SCOPE} Edit User Intent Success`;
        constructor() { }
    }

    export class EditUserIntentFailed {
        static readonly type = `${ACTION_SCOPE} Edit User Intent Failed`;
        constructor() { }
    }

    export class DeleteUserIntent {
        static readonly type = `${ACTION_SCOPE} Delete User Intent`;
        constructor(public uri: string) { }
    }

    export class DeleteUserIntentSuccess {
        static readonly type = `${ACTION_SCOPE} Delete User Intent Success`;
        constructor() { }
    }

    export class DeleteUserIntentFailed {
        static readonly type = `${ACTION_SCOPE} Delete User Intent Failed`;
        constructor() { }
    }

    export class SentUserInvitation {
        static readonly type = `${ACTION_SCOPE} Sent User Invitation`;
        constructor(public payload: any, public uri: string) { }
    }

    export class SentUserInvitationSuccess {
        static readonly type = `${ACTION_SCOPE} Sent User Invitation Success`;
        constructor() { }
    }

    export class SentUserInvitationFailed {
        static readonly type = `${ACTION_SCOPE} Sent User Invitation Failed`;
        constructor() { }
    }

    

}