
const ACTION_SCOPE = '[Auth]';

export namespace AuthActions {
    export class LoginUser {
        static readonly type = `${ACTION_SCOPE} Login User`
        constructor(public payload: any, public uri: string) { }
    }

    export class LoginSuccess {
        static readonly type = `${ACTION_SCOPE} Login Success`
        constructor() { }
    }

    export class LoginFailed {
        static readonly type = `${ACTION_SCOPE} Login Failed`
        constructor() { }
    }

    export class GetUserLogin {
        static readonly type = `${ACTION_SCOPE} Get User Login`
        constructor(public uri: string) { }
    }

    export class LogoutUser {
        static type = `${ACTION_SCOPE} Logout User`;
        constructor(public uri: string) { }
    }

    export class LogoutSuccess {
        static type = `${ACTION_SCOPE} Logout Success`;
        constructor() { }
    }

    export class LogoutFailed {
        static type = `${ACTION_SCOPE} Logout Success`;
        constructor() { }
    }

    export class ResetDefaultValue {
        static type = `${ACTION_SCOPE} Reset default value`;
        constructor() { }
    }
}