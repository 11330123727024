import { animate, style, transition, trigger } from "@angular/animations";

export const fade500 = trigger('fade', [
    transition('void => *', [
      style({opacity: 0}),
      animate(500, style({opacity: 1}))
    ]),
    transition('* => void', [
      animate(500, style({opacity: 0}))
    ])
  ]);
  
  export const fade1000 = trigger('fade', [
    transition('void => *', [
      style({opacity: 0}),
      animate(1000, style({opacity: 1}))
    ]),
    transition('* => void', [
      animate(1000, style({opacity: 0}))
    ])
  ]);