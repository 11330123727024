<c-row class="g-4">
  <c-col sm="6" xl="3">
    <c-widget-stat-a
      color="primary"
      [title]="'Users'"
    >
      <ng-template cTemplateId="widgetValueTemplate" ngPreserveWhitespaces>
        26K
        <span class="fw-normal" style="font-size: 12px;">
          (-12.4% <svg cIcon name="cilArrowBottom" size="sm"></svg>)
        </span>
      </ng-template>
      <ng-template cTemplateId="widgetChartTemplate">
        <c-chart [data]="data[0]" [options]="options[0]" class="mt-3 mx-3" height="70" [type]="'line'"></c-chart>
      </ng-template>
    </c-widget-stat-a>
  </c-col>
  <c-col sm="6" xl="3">
    <c-widget-stat-a
      [title]="'Income'"
      color="info"
    >
      <ng-template cTemplateId="widgetValueTemplate" ngPreserveWhitespaces>
        <span>$6.200</span>
        <span class="fw-normal" style="font-size: 12px;">
          (40.9%<svg cIcon name="cilArrowTop" size="sm"></svg>)
        </span>
      </ng-template>
      <ng-template cTemplateId="widgetChartTemplate">
        <c-chart [data]="data[1]" [options]="options[1]" class="mt-3 mx-3" height="70" type="line"></c-chart>
      </ng-template>
    </c-widget-stat-a>
  </c-col>
  <c-col sm="6" xl="3">
    <c-widget-stat-a
      [title]="'Conversion Rate'"
      color="warning"
    >
      <ng-template cTemplateId="widgetValueTemplate" ngPreserveWhitespaces>
        <span>2.49</span>
        <span class="fw-normal" style="font-size: 12px;">
          (84.7% <svg cIcon name="cilArrowTop" size="sm"></svg>)
        </span>
      </ng-template>
      
      <ng-template cTemplateId="widgetChartTemplate">
        <c-chart [data]="data[2]" [options]="options[2]" class="mt-3" height="70" type="line"></c-chart>
      </ng-template>
    </c-widget-stat-a>
  </c-col>
  <c-col sm="6" xl="3">
    <c-widget-stat-a
      [title]="'Sessions'"
      color="danger"
    >
      <ng-template cTemplateId="widgetValueTemplate" ngPreserveWhitespaces>
        <span>44K</span>
        <span class="fw-normal" style="font-size: 12px;">
          (-23.6% <svg cIcon name="cilArrowBottom" size="sm"></svg>)
        </span>
      </ng-template>
      
      <ng-template cTemplateId="widgetChartTemplate">
        <c-chart [data]="data[3]" [options]="options[3]" class="mt-3 mx-3" height="70" type="bar"></c-chart>
      </ng-template>
    </c-widget-stat-a>
  </c-col>
</c-row>
