export namespace AppActions {

  const ACTION_SCOPE_APP = '[APP]';

  export class DisableLogoutMessage {
    static type = `${ACTION_SCOPE_APP} Disable Logout Message`;
    constructor() { }
  }

  export class LoadSystemData {
    static type = `${ACTION_SCOPE_APP} Load System Data`;
    constructor(public uri: string) { }
  }
}