import { INavData } from '@coreui/angular';

export const navItemsRoot: INavData[] = [
  {
    name: 'Accounts',
    url: '/accounts',
    iconComponent: { name: 'cil-group' },
    badge: {
      color: 'gray',
      text: 'ROOT'
    }
  },
  {
    name: 'Organizations',
    url: '/organizations',
    iconComponent: { name: 'cil-lan' },
    badge: {
      color: 'gray',
      text: 'ROOT'
    }
  }
];
