import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Action, Selector, State, StateContext } from "@ngxs/store"
import { FunctionsActions } from "./functions.actions"
import { catchError, mergeMap, tap, throwError } from "rxjs"
import { response } from "express"
import { Functions } from "../../models/function"

export class FunctionsStateModel {
    loading: boolean | undefined
    error: any
    functionList: Functions[] | undefined 
    functionSelected: Functions | undefined | null
    next: any
    previous: any
}

const defaults: FunctionsStateModel = {
    loading: false,
    error: null,
    functionList: [],
    functionSelected: null,
    next: null,
    previous: null
}

@State<FunctionsStateModel>({
    name: 'functions',
    defaults
})
@Injectable()
export class FunctionsState {

    @Selector()
    static isLoading(state: FunctionsStateModel) { return state.loading; }

    @Selector()
    static getFunctionsError(state: FunctionsStateModel) { return state.error; }

    @Selector()
    static getFunctionList(state: FunctionsStateModel) { return state.functionList }

    @Selector()
    static getFunctionSelected(state: FunctionsStateModel) { return state.functionSelected }

    @Selector()
    static getLoadNext(state: FunctionsStateModel) { return state.next }

    @Selector()
    static getLoadPrevious(state: FunctionsStateModel) { return state.previous }

    constructor(private http: HttpClient) { }

    @Action(FunctionsActions.LoadFunctions)
    loadFunctions(ctx: StateContext<FunctionsStateModel>, { uri }: FunctionsActions.LoadFunctions) {
        ctx.patchState({ loading: true, error: null });
        return this.http.get(uri).pipe(tap((resp: any) => {
            ctx.patchState({
                loading: false,
                functionList: resp.entities,
                next: (resp && resp.resources.next) ? resp.resources.next.uri.split("?")[1] : null,
                previous: (resp && resp.resources.previous) ? resp.resources.previous.uri.split("?")[1] : null
            });
        }),
            catchError((err) => {
                ctx.patchState({ loading: false, functionList: [], next: null, previous: null, error: err.error.errors ? err.error.errors.toString() : err.error.message });
                return throwError(() => err);
            }));
    }

    @Action(FunctionsActions.Reset)
    reset(ctx: StateContext<FunctionsStateModel>, { }: FunctionsActions.Reset) {
        ctx.patchState({ functionSelected: null, error: null });
    }

    @Action(FunctionsActions.SelectedFunction)
    selectFunction(ctx: StateContext<FunctionsStateModel>, { funct }: FunctionsActions.SelectedFunction) {
        ctx.patchState({ functionSelected: funct });
    }

    @Action(FunctionsActions.CreateFunctionIntent)
    createFunctionIntent(ctx: StateContext<FunctionsStateModel>, { payload, uri }: FunctionsActions.CreateFunctionIntent) {
        ctx.patchState({ loading: true, error: null });
        return this.http.post(uri, payload).pipe(tap((result: any) => {
            ctx.patchState({ loading: false })
        }),
            mergeMap(() => {
                return ctx.dispatch(new FunctionsActions.CreateFunctionIntentSuccess())
            }),
            catchError(err => {
                ctx.patchState({ loading: false, error: err.error.errors ? err.error.errors.toString() : err.error.message });
                return ctx.dispatch(new FunctionsActions.CreateFunctionIntentFailed())
            })
        );
    }

    @Action(FunctionsActions.EditFunctionIntent)
    editFunctionIntent(ctx: StateContext<FunctionsStateModel>, { payload, uri }: FunctionsActions.EditFunctionIntent) {
        ctx.patchState({ loading: true, error: null });
        return this.http.put(uri, payload).pipe(tap(() => {
            ctx.patchState({ loading: false })
        }),
            mergeMap(() => {
                return ctx.dispatch(new FunctionsActions.EditFunctionIntentSuccess())
            }),
            catchError(err => {
                ctx.patchState({ loading: false, error: err.error.errors ? err.error.errors.toString() : err.error.message });
                return ctx.dispatch(new FunctionsActions.EditFunctionIntentFailed())
            })
        );
    }

    @Action(FunctionsActions.DeleteFunctionIntent)
    deleteFunctionIntent(ctx: StateContext<FunctionsStateModel>, { uri }: FunctionsActions.DeleteFunctionIntent) {
        ctx.patchState({ loading: true, error: null });
        return this.http.delete(uri).pipe(tap(() => {
            ctx.patchState({ loading: false })
            return ctx.dispatch(new FunctionsActions.DeleteFunctionIntentSuccess())
        }),
            catchError(err => {
                ctx.patchState({ loading: false, error: err.error.errors ? err.error.errors.toString() : err.error.message });
                return ctx.dispatch(new FunctionsActions.DeleteFunctionIntentFailed())
            })
        );
    }

    @Action(FunctionsActions.ResetDefault)
    resetDefault(context: StateContext<FunctionsStateModel>) {
        context.setState({ ...defaults });
    }
}