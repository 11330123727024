import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { NgStyle, CommonModule } from '@angular/common';
import { IconDirective } from '@coreui/icons-angular';
import {
  ContainerComponent, RowComponent, ColComponent, CardGroupComponent, TextColorDirective, CardComponent, CardBodyComponent,
  FormDirective, InputGroupComponent, InputGroupTextDirective, FormControlDirective, ButtonDirective, AlertComponent
} from '@coreui/angular';
import { merge, Observable, Subject, takeUntil } from 'rxjs';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { AuthState } from '../../store/auth/auth.state';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { AuthActions } from '../../store/auth/auth.actions';
import { ApiPaths } from '../../shared/enums/api-path.enum';
import { fade1000 } from '../../shared/animations/animations';
import { OrganizationsActions } from '../../store/organizations/organizations.actions';
import { AppState } from '../../store/app/app.state';
import { AppActions } from '../../store/app/app.actions';
import { ApiUtils } from "../../shared/utils/api.utils";
import { SessionToken } from '../../models/session-token';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [ContainerComponent, RowComponent, ColComponent, CardGroupComponent, TextColorDirective, CardComponent, CardBodyComponent, FormDirective,
    InputGroupComponent, InputGroupTextDirective, IconDirective, FormControlDirective, ButtonDirective, NgStyle, ReactiveFormsModule, CommonModule, AlertComponent],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
  animations: [fade1000]
})
export class LoginComponent implements OnInit, OnDestroy {
  dismissible = true;
  message: string = '';
  msgType: string = 'light';
  errorObs?: Observable<any> = inject(Store).select(AuthState.getAuthError);
  private destroy: Subject<boolean> = new Subject<boolean>();
  public submitted = false;
  readonly loginFormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required])
  });

  errorEmailMessage = signal('');
  errorPasswordMessage = signal('');
  hide = signal(true);
  public sessionToken: SessionToken | undefined;

  constructor(private store: Store, private router: Router, private actions: Actions) {
    merge(this.loginFormGroup.controls.email.statusChanges, this.loginFormGroup.controls.email.valueChanges)
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.updateEmailErrorMessage());

    merge(this.loginFormGroup.controls.password.statusChanges, this.loginFormGroup.controls.password.valueChanges)
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.updatePasswordErrorMessage());
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

  ngOnInit(): void {
    const session = this.store.selectSnapshot<any>(AuthState.getSession);
    if (session) {
      this.router.navigate(['/']);
    } else {
      this.monitorEvents()
      this.monitorOneTimeEvents();
    }
  }

  showMessage(type: string, message: string) {
    this.msgType = type;
    this.message = message;
  }

  monitorEvents() {
    this.errorObs?.subscribe((message) => {
      if (message) {
        this.showMessage("danger", message);
      }
    });
    this.actions.pipe(ofActionDispatched(AuthActions.LoginSuccess)).pipe(takeUntil(this.destroy)).subscribe(() => {
      this.loadOrganizations();
      this.router.navigate(['/login-organizations']);
    });

    this.actions.pipe(ofActionDispatched(AuthActions.LogoutSuccess)).pipe(takeUntil(this.destroy)).subscribe(() => {
      this.showMessage("success", "You've been successfully logged out. Have a nice day. ");
    });
  }

  monitorOneTimeEvents() {
    if (this.store.selectSnapshot<any>(AppState.getLogoutStatus)) {
      this.store.dispatch(new AppActions.DisableLogoutMessage);
      this.showMessage("success", "You've been successfully logged out. Have a nice day. ");
    }
  }

  onSignIn() {
    if (this.loginFormGroup.valid) {
      this.submitted = true;
      const payload = {
        email: this.loginFormGroup.value.email,
        password: this.loginFormGroup.value.password,
        requestSuperUser: true
      };
      this.store.dispatch(new AuthActions.LoginUser(payload, ApiUtils.resolveUri(ApiPaths.Login)))
    }
  }

  updateEmailErrorMessage() {
    if (this.loginFormGroup.controls.email.hasError('required')) {
      this.errorEmailMessage.set('You must enter a value');
    } else if (this.loginFormGroup.controls.email.hasError('email')) {
      this.errorEmailMessage.set('Not a valid email');
    } else {
      this.errorEmailMessage.set('');
    }
  }

  updatePasswordErrorMessage() {
    if (this.loginFormGroup.controls.password.hasError('required')) {
      this.errorPasswordMessage.set('You must enter a value');
    } else {
      this.errorPasswordMessage.set('');
    }
  }

  loadOrganizations() {
    this.sessionToken = this.store.selectSnapshot<any>(AuthState.getSession);
    this.store.dispatch(new OrganizationsActions.LoadOrganizations(ApiUtils.resolveUri(this.sessionToken?.entity.isSuperUser ? ApiPaths.Organizations : ApiPaths.LoadOrganizations)));
  }

}
