import { Injectable} from '@angular/core';
import {APP_SETTINGS} from "./app-settings";

@Injectable({ providedIn: 'root' })
export class ApiUtils {

  constructor() { }

  static resolveUri(segment: string) {
    return APP_SETTINGS.api + segment;
  }

  static resolveObjectUri(object: any, segment: string) {
    return APP_SETTINGS.api + object.resources[segment].uri;
  }

  static resolveUriWithVar(object: any, varName: string, varValue: string, segment: string) {
    const uri = object.resources[segment].uri;
    return APP_SETTINGS.api + uri.replace(varName, varValue);
  }
}
