import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Action, Selector, State, StateContext } from "@ngxs/store"
import { FilesActions } from "./files.actions"
import { catchError, mergeMap, tap, throwError } from "rxjs"
import { Files } from "../../models/files"

export class FilesStateModel {
    loading: boolean | undefined
    error: any
    filesList: Files[] | undefined
    fileSelected: Files | undefined | null
    next: any
    previous: any
}

const defaults: FilesStateModel = {
    loading: false,
    error: null,
    filesList: [],
    fileSelected: null,
    next: null,
    previous: null
}

@State<FilesStateModel>({
    name: 'files',
    defaults
})
@Injectable()
export class FilesState {

    @Selector()
    static isLoading(state: FilesStateModel) { return state.loading; }

    @Selector()
    static getFilesError(state: FilesStateModel) { return state.error; }

    @Selector()
    static getFilesList(state: FilesStateModel) { return state.filesList }

    @Selector()
    static getFileSelected(state: FilesStateModel) { return state.fileSelected }

    @Selector()
    static getLoadNext(state: FilesStateModel) { return state.next }

    @Selector()
    static getLoadPrevious(state: FilesStateModel) { return state.previous }

    constructor(private http: HttpClient) { }

    @Action(FilesActions.LoadFiles)
    loadFiles(ctx: StateContext<FilesStateModel>, { uri }: FilesActions.LoadFiles) {
        ctx.patchState({ loading: true, error: null });
        return this.http.get(uri).pipe(tap((resp: any) => {
            ctx.patchState({
                loading: false,
                filesList: resp.entities,
                next: (resp && resp.resources.next) ? resp.resources.next.uri.split("?")[1] : null,
                previous: (resp && resp.resources.previous) ? resp.resources.previous.uri.split("?")[1] : null
            });
        }),
            catchError((err) => {
                ctx.patchState({ loading: false, filesList: [], next: null, previous: null, error: err.error.errors ? err.error.errors.toString() : err.error.message });
                return throwError(() => err);
            }));
    }

    @Action(FilesActions.Reset)
    reset(ctx: StateContext<FilesStateModel>, { }: FilesActions.Reset) {
        ctx.patchState({ fileSelected: null, error: null });
    }

    @Action(FilesActions.SelectedFile)
    selectedFile(ctx: StateContext<FilesStateModel>, { file }: FilesActions.SelectedFile) {
        ctx.patchState({ fileSelected: file });
    }

    @Action(FilesActions.CreateFileIntent)
    createFileIntent(ctx: StateContext<FilesStateModel>, { payload, uri }: FilesActions.CreateFileIntent) {
        ctx.patchState({ loading: true, error: null });
        return this.http.post(uri, payload).pipe(tap((result: any) => {
            ctx.patchState({ loading: false })
        }),
            mergeMap(() => {
                return ctx.dispatch(new FilesActions.CreateFileIntentSuccess())
            }),
            catchError(err => {
                ctx.patchState({ loading: false, error: err.error.errors ? err.error.errors.toString() : err.error.message });
                return ctx.dispatch(new FilesActions.CreateFileIntentFailed())
            })
        );
    }

    @Action(FilesActions.EditFileIntent)
    editFilesetIntent(ctx: StateContext<FilesStateModel>, { payload, uri }: FilesActions.EditFileIntent) {
        ctx.patchState({ loading: true, error: null });
        return this.http.patch(uri, payload).pipe(tap(() => {
            ctx.patchState({ loading: false })
        }),
            mergeMap(() => {
                return ctx.dispatch(new FilesActions.EditFileIntentSuccess())
            }),
            catchError(err => {
                ctx.patchState({ loading: false, error: err.error.errors ? err.error.errors.toString() : err.error.message });
                return ctx.dispatch(new FilesActions.EditFileIntentFailed())
            })
        );
    }

    @Action(FilesActions.DeleteFileIntent)
    deleteFileIntent(ctx: StateContext<FilesStateModel>, {  uri }: FilesActions.DeleteFileIntent) {
        ctx.patchState({ loading: true, error: null });
        return this.http.delete(uri).pipe(tap(() => {
            ctx.patchState({ loading: false })
            return ctx.dispatch(new FilesActions.DeleteFileIntentSuccess())
        }),
            catchError(err => {
                ctx.patchState({ loading: false, error: err.error.errors ? err.error.errors.toString() : err.error.message });
                return ctx.dispatch(new FilesActions.DeleteFileIntentFailed())
            })
        );
    }

    @Action(FilesActions.DownloadFile)
    downloadFile(ctx: StateContext<FilesStateModel>, { uri }: FilesActions.DownloadFile) {
        ctx.patchState({ loading: true });
        return this.http.get(uri, {responseType: 'blob', observe: 'response'}).pipe(tap((resp: any) => {
            let fileName = resp.headers.get('content-disposition')?.split(';')[1].split('=')[1].replaceAll('"', '');

            let element = document.createElement('a');
            element.href = window.URL.createObjectURL(resp.body);
            element.download = fileName;
            element.click();

            ctx.patchState({ loading: false })
            return ctx.dispatch(new FilesActions.DownloadFileSuccess())
        }),
            catchError((err) => {
                ctx.patchState({ loading: false, error: err.error.errors ? err.error.errors.toString() : err.error.message });
                return ctx.dispatch(new FilesActions.DownloadFileFailed())
            }));
    }
}