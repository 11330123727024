const ACTION_SCOPE = '[Accounts]';

export namespace AccountsActions {
  export class LoadAccounts {
    static readonly type = `${ACTION_SCOPE} List Account`;
    constructor(public uri: string) {}
  }

  export class SelectedAccount {
    static readonly type = `${ACTION_SCOPE} Selected Account`;
    constructor(public account: any) {}
  }

  export class CreateAccountIntent {
    static readonly type = `${ACTION_SCOPE} Create Account Intent`;
    constructor(public payload: any, public uri: string) {}
  }

  export class CreateAccountIntentSuccess {
    static readonly type = `${ACTION_SCOPE} Create Account Intent Success`;
    constructor() {}
  }

  export class CreateAccountIntentFailed {
    static readonly type = `${ACTION_SCOPE} Create Account Intent Failed`;
    constructor() {}
  }

  export class EditAccountIntent {
    static readonly type = `${ACTION_SCOPE} Edit Account Intent`;
    constructor(public payload: any, public uri: string) {}
  }

  export class EditAccountIntentSuccess {
    static readonly type = `${ACTION_SCOPE} Edit Account Intent Success`;
    constructor() {}
  }

  export class EditAccountIntentFailed {
    static readonly type = `${ACTION_SCOPE} Edit Account Intent Failed`;
    constructor() {}
  }

  export class DeleteAccountIntent {
    static readonly type = `${ACTION_SCOPE} Delete Account Intent`;
    constructor(public uri: string) {}
  }

  export class DeleteAccountIntentSuccess {
    static readonly type = `${ACTION_SCOPE} Delete Account Intent Success`;
    constructor() {}
  }

  export class DeleteAccountIntentFailed {
    static readonly type = `${ACTION_SCOPE} Delete Account Intent Failed`;
    constructor() {}
  }
}
