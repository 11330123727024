import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { MSG } from "../mesages/messages";

@Injectable()
export class FileUtils {

    constructor(private toastr: ToastrService) { }

    isValidSize(file: any): boolean {
        if (file && file.size > 10000000) {
            this.toastr.error(MSG.invalidFile);
            return false;
        }
        return true;
    }

}