<!--<c-footer>-->
<div>
    <a href="https://linkpal.ai" target="_blank">Linkpal</a>
    <span> &copy; 2024</span>
</div>
<div class="ms-auto">
    Powered by
    <a href="https://huru.io" target="_blank">
        <span> Huru Inc</span>
    </a>
</div>
<!--</c-footer>-->
