import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AvatarComponent,
  BreadcrumbRouterComponent,
  ContainerComponent,
  DropdownComponent,
  DropdownDividerDirective,
  DropdownHeaderDirective,
  DropdownItemDirective,
  DropdownMenuDirective,
  DropdownToggleDirective,
  FormDirective,
  HeaderComponent,
  HeaderNavComponent,
  HeaderTogglerDirective,
  SidebarToggleDirective,
  TextColorDirective,
  ThemeDirective
} from '@coreui/angular';
import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { IconDirective } from '@coreui/icons-angular';
import { takeUntil } from 'rxjs/operators';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { AuthActions } from '../../store/auth/auth.actions';
import { Observable, Subject } from 'rxjs';
import { OrganizationsState } from '../../store/organizations/organizations.state';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrganizationsActions } from '../../store/organizations/organizations.actions';
import { Organization } from '../../models/organization';
import { ApiPaths } from '../../shared/enums/api-path.enum';
import {ApiUtils} from "../../shared/utils/api.utils";

@Component({
  selector: 'app-header-layout',
  standalone: true,
  imports: [ContainerComponent, HeaderTogglerDirective, SidebarToggleDirective, IconDirective, HeaderNavComponent, RouterLink, NgTemplateOutlet, BreadcrumbRouterComponent,
    ThemeDirective, DropdownComponent, DropdownToggleDirective, TextColorDirective, AvatarComponent, DropdownMenuDirective, DropdownHeaderDirective,
    DropdownItemDirective, DropdownDividerDirective, FormDirective, ReactiveFormsModule,
    CommonModule, FormsModule],
  templateUrl: './header-layout.component.html',
  styleUrl: './header-layout.component.scss'
})
export class HeaderLayoutComponent extends HeaderComponent implements OnInit, OnDestroy {

  private destroy: Subject<boolean> = new Subject<boolean>();
  organizationsObs?: Observable<any> = inject(Store).select(OrganizationsState.getOrganizationList);
  formGroup: FormGroup;

  organizations: Organization[] = [];
  selectedOrganization: Organization | undefined | null;

  constructor(private store: Store, private router: Router, private actions: Actions) {
    super();
    this.selectedOrganization = this.store.selectSnapshot<any>(OrganizationsState.getSelectedOrganization);
    this.formGroup = new FormGroup({
      selectedOrganization: new FormControl(
        this.selectedOrganization
          ? this.selectedOrganization.entity.uuid
          : null,
        null
      ),
    });
  }
  ngOnInit(): void {
    this.organizationsObs?.subscribe((organizations: []) => {
      if (organizations && organizations.length > 0) {
        this.organizations = organizations;
      }
    });

    this.actions.pipe(ofActionDispatched(AuthActions.LogoutSuccess)).pipe(takeUntil(this.destroy)).subscribe(() => {
      this.router.navigate(['/login']);
    });
  }
  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

  @Input() sidebarId: string = 'sidebar1';

  onLogout() {
    this.store.dispatch(new AuthActions.LogoutUser(ApiUtils.resolveUri(ApiPaths.Logout)));
  }

  onSelect(form: any): void {
    this.selectedOrganization = this.organizations.find(org => org.entity.uuid === form.selectedOrganization)!;
    this.store.dispatch(new OrganizationsActions.SelectOrganization(this.selectedOrganization));
    this.router.navigate(['/dashboard']);
  }

}
