<app-widgets-dropdown />
<c-card class="my-4">
    <c-card-body>
        <c-row>
            <c-col sm="5">
                <h4 class="card-title mb-0" id="traffic">Traffic</h4>
                <div class="small text-body-secondary">January - October 2024</div>
            </c-col>
            <c-col class="d-none d-md-block" sm="7">
                <form [formGroup]="trafficRadioGroup">
                    <c-button-group class="float-end me-3" role="group">
                        <input class="btn-check" formControlName="trafficRadio" type="radio" value="Day"
                            id="dayRadio" />
                        <label (click)="setTrafficPeriod('Day')" cButton cFormCheckLabel color="secondary"
                            variant="outline" for="dayRadio">
                            Day
                        </label>
                        <input class="btn-check" formControlName="trafficRadio" type="radio" value="Month"
                            id="radioMonth" />
                        <label (click)="setTrafficPeriod('Month')" cButton cFormCheckLabel color="secondary"
                            variant="outline" for="radioMonth">
                            Month
                        </label>
                        <input class="btn-check" formControlName="trafficRadio" type="radio" value="Year"
                            id="radioYear" />
                        <label (click)="setTrafficPeriod('Year')" cButton cFormCheckLabel color="secondary"
                            variant="outline" for="radioYear">
                            Year
                        </label>
                    </c-button-group>
                </form>
            </c-col>
        </c-row>
        <c-chart [data]="mainChart.data" [height]="300" [options]="mainChart.options" [type]="mainChart.type"
            (chartRef)="handleChartRef($event)">
            Main chart
        </c-chart>
    </c-card-body>
    <c-card-footer>
        <c-row class="text-center mb-2" [xl]="5" [lg]="4" [sm]="2" [xs]="1" [gutter]="4">
          <c-col>
            <div class="text-body-secondary">Visits</div>
            <strong>29.703 Users (40%)</strong>
            <c-progress class="mt-2" thin color="success" value="40" aria-label="User visits" />
          </c-col>
          <c-col>
            <div class="text-body-secondary">Unique</div>
            <div class="fw-semibold text-truncate">24.093 Users (20%)</div>
            <c-progress class="mt-2" thin color="info" value="20" aria-label="Unique users" />
          </c-col>
          <c-col>
            <div class="text-body-secondary">Page views</div>
            <div class="fw-semibold text-truncate">78.706 Views (60%)</div>
            <c-progress class="mt-2" thin color="warning" value="60" aria-label="Page views" />
          </c-col>
          <c-col>
            <div class="text-body-secondary">New Users</div>
            <div class="fw-semibold text-truncate">22.123 Users (80%)</div>
            <c-progress class="mt-2" thin color="danger" value="80" aria-label="New users" />
          </c-col>
          <c-col class="d-none d-xl-block">
            <div class="text-body-secondary">Bounce Rate</div>
            <div class="fw-semibold text-truncate">Average Rate (40.15%)</div>
            <c-progress class="mt-2" thin value="40" aria-label="Bounce rate" />
          </c-col>
        </c-row>
      </c-card-footer>
</c-card>
<c-row class="mt-4">
    <c-col xs>
      <c-card class="mb-4">
        <c-card-header>Traffic {{ "&" }} Sales</c-card-header>
        <c-card-body>
          <c-row>
            <c-col md="6" xl="6" xs="12">
              <c-row>
                <c-col xs="6">
                  <div class="border-start border-start-4 border-start-info py-1 px-3 mb-3">
                    <div class="text-body-secondary text-truncate small">New Clients</div>
                    <div class="fs-5 fw-semibold">9,123</div>
                  </div>
                </c-col>
                <c-col xs="6">
                  <div class="border-start border-start-4 border-start-danger py-1 px-3 mb-3">
                    <div class="text-body-secondary text-truncate small">
                      Recurring Clients
                    </div>
                    <div class="fs-5 fw-semibold">22,643</div>
                  </div>
                </c-col>
              </c-row>
              <hr class="mt-0" />
              <div class="progress-group mb-4">
                <div class="progress-group-prepend">
                  <span class="text-body-secondary small">Monday</span>
                </div>
                <div class="progress-group-bars">
                  <c-progress thin color="info" value="34" aria-label="Monday new clients" />
                  <c-progress thin color="danger" value="78" aria-label="Monday recurring clients" />
                </div>
              </div>
              <div class="progress-group mb-4">
                <div class="progress-group-prepend">
                  <span class="text-body-secondary small">Tuesday</span>
                </div>
                <div class="progress-group-bars">
                  <c-progress thin color="info" value="56" aria-label="Tuesday new clients" />
                  <c-progress thin color="danger" value="94" aria-label="Tuesday recurring clients" />
                </div>
              </div>
              <div class="progress-group mb-4">
                <div class="progress-group-prepend">
                  <span class="text-body-secondary small">Wednesday</span>
                </div>
                <div class="progress-group-bars">
                  <c-progress thin color="info" value="12" aria-label="Wednesday new clients" />
                  <c-progress thin color="danger" value="67" aria-label="Wednesday recurring clients" />
                </div>
              </div>
              <div class="progress-group mb-4">
                <div class="progress-group-prepend">
                  <span class="text-body-secondary small">Thursday</span>
                </div>
                <div class="progress-group-bars">
                  <c-progress thin color="info" value="43" aria-label="Thursday new clients" />
                  <c-progress thin color="danger" value="91" aria-label="Thursday recurring clients" />
                </div>
              </div>
              <div class="progress-group mb-4">
                <div class="progress-group-prepend">
                  <span class="text-body-secondary small">Friday</span>
                </div>
                <div class="progress-group-bars">
                  <c-progress thin color="info" value="22" aria-label="Friday new clients" />
                  <c-progress thin color="danger" value="73" aria-label="Friday recurring clients" />
                </div>
              </div>
              <div class="progress-group mb-4">
                <div class="progress-group-prepend">
                  <span class="text-body-secondary small">Saturday</span>
                </div>
                <div class="progress-group-bars">
                  <c-progress thin color="info" value="53" aria-label="Saturday new clients" />
                  <c-progress thin color="danger" value="82" aria-label="Saturday recurring clients" />
                </div>
              </div>
              <div class="progress-group mb-4">
                <div class="progress-group-prepend">
                  <span class="text-body-secondary small">Sunday</span>
                </div>
                <div class="progress-group-bars">
                  <c-progress thin color="info" value="9" aria-label="Sunday new clients" />
                  <c-progress thin color="danger" value="69" aria-label="Sunday recurring clients" />
                </div>
              </div>
              <div class="legend text-center d-none d-md-block" ngPreserveWhitespaces>
                <small>
                  <sup>
                    <span class="badge badge-pill badge-sm bg-info">&nbsp;</span>
                  </sup>
                  <span>New clients</span>
                  &nbsp;&nbsp;
                  <sup>
                    <span class="badge badge-pill badge-sm bg-danger">&nbsp;</span>
                  </sup>
                  <span>Recurring clients</span>
                </small>
              </div>
            </c-col>
  
            <c-col md="6" xl="6" xs="12">
              <c-row>
                <c-col xs="6">
                  <div class="border-start border-start-4 border-start-warning py-1 px-3 mb-3">
                    <div class="text-body-secondary text-truncate small">Page views</div>
                    <div class="fs-5 fw-semibold">78,623</div>
                  </div>
                </c-col>
                <c-col xs="6">
                  <div class="border-start border-start-4 border-start-success py-1 px-3 mb-3">
                    <div class="text-body-secondary text-truncate small">Organic</div>
                    <div class="fs-5 fw-semibold">49,123</div>
                  </div>
                </c-col>
              </c-row>
  
              <hr class="mt-0" />
  
              <div class="progress-group mb-4">
                <div class="progress-group-header">
                  <svg cIcon class="icon icon-lg me-2" name="cilUser"></svg>
                  <span>Male</span>
                  <span class="ms-auto font-semibold">43%</span>
                </div>
                <div class="progress-group-bars">
                  <c-progress thin color="warning" value="43" aria-label="Male users" />
                </div>
              </div>
              <div class="progress-group mb-5">
                <div class="progress-group-header">
                  <svg cIcon class="icon icon-lg me-2" name="cilUserFemale"></svg>
                  <span>Female</span>
                  <span class="ms-auto font-semibold">37%</span>
                </div>
                <div class="progress-group-bars">
                  <c-progress thin color="warning" value="37" aria-label="Feale users" />
                </div>
              </div>
  
              <div class="progress-group">
                <div class="progress-group-header">
                  <svg cIcon class="icon icon-lg me-2" name="cibGoogle"></svg>
                  <span>Organic Search</span>
                  <span class="ms-auto font-semibold">
                    191,235 <span class="text-body-secondary small">(56%)</span>
                  </span>
                </div>
                <div class="progress-group-bars">
                  <c-progress thin color="success" value="56" aria-label="Organic search" />
                </div>
              </div>
              <div class="progress-group">
                <div class="progress-group-header">
                  <svg cIcon class="icon icon-lg me-2" name="cibFacebook"></svg>
                  <span>Facebook</span>
                  <span class="ms-auto font-semibold">
                    51,223 <span class="text-body-secondary small">(15%)</span>
                  </span>
                </div>
                <div class="progress-group-bars">
                  <c-progress thin color="success" value="15" aria-label="Facebook" />
                </div>
              </div>
              <div class="progress-group">
                <div class="progress-group-header">
                  <svg cIcon class="icon icon-lg me-2" name="cibTwitter"></svg>
                  <span>Twitter</span>
                  <span class="ms-auto font-semibold">
                    37,564 <span class="text-body-secondary small">(11%)</span>
                  </span>
                </div>
                <div class="progress-group-bars">
                  <c-progress thin color="success" value="11" aria-label="Twitter" />
                </div>
              </div>
              <div class="progress-group">
                <div class="progress-group-header">
                  <svg cIcon class="icon icon-lg me-2" name="cibLinkedin"></svg>
                  <span>LinkedIn</span>
                  <span class="ms-auto font-semibold">
                    27,319 <span class="text-body-secondary small">(8%)</span>
                  </span>
                </div>
                <div class="progress-group-bars">
                  <c-progress thin color="success" value="8" aria-label="LinkedIn" />
                </div>
              </div>
              <div class="divider d-flex justify-content-center">
                <button
                  cButton
                  class="text-muted btn-link"
                  color="transparent"
                  size="sm"
                  type="button"
                  aria-label="Options"
                >
                  <svg cIcon name="cil-options"></svg>
                </button>
              </div>
            </c-col>
          </c-row>
        </c-card-body>
      </c-card>
    </c-col>
  </c-row>
  <c-row>
    <c-col xs>
      <c-card class="mb-4">
        <c-card-body>
          <table
            [hover]="true"
            [responsive]="true"
            [striped]="true"
            align="middle"
            cTable
            class="mb-0 border"
          >
            <thead class="text-nowrap text-truncate">
            <tr>
              <th class="bg-body-tertiary text-center ">
                <svg cIcon name="cilPeople"></svg>
              </th>
              <th class="bg-body-tertiary">User</th>
              <th class="bg-body-tertiary text-center">Country</th>
              <th class="bg-body-tertiary">Usage</th>
              <th class="bg-body-tertiary text-center">Payment Method</th>
              <th class="bg-body-tertiary">Activity</th>
            </tr>
            </thead>
            <tbody>
              @for (user of users; track user.name; let i = $index) {
                <tr>
                  <td class="text-center">
                    <c-avatar [size]="'md'" src="../../../assets/images/avatars/2.jpg" status="{{ user.status }}" />
                  </td>
                  <td>
                    <div>{{ user.name }}</div>
                    <div class="small text-body-secondary text-nowrap">
                    <span>
                      {{ user.state }} | Registered: {{ user.registered }}
                    </span>
                    </div>
                  </td>
                  <td class="text-center">
                    <svg
                      cIcon
                      id="{{ user.country + user.name }}"
                      name="cif{{ user.country }}"
                      size="xl"
                      title="{{ user.country }}"
                    ></svg>
                  </td>
                  <td>
                    <div class="d-flex justify-content-between">
                      <div class="float-start">
                        <strong>{{ user.usage }}%</strong>
                      </div>
                      <div class="float-end ms-1 text-nowrap">
                        <small class="text-body-secondary">
                          {{ user.period }}
                        </small>
                      </div>
                    </div>
                    <c-progress thin [value]="user.usage" color="{{ user.color }}" aria-label="Usage" />
                  </td>
                  <td class="text-center">
                    <svg cIcon name="cibCc{{ user.payment }}" size="xl"></svg>
                  </td>
                  <td>
                    <div class="small text-body-secondary">Last login</div>
                    <div class="fw-semibold text-nowrap">{{ user.activity }}</div>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </c-card-body>
      </c-card>
    </c-col>
  </c-row>