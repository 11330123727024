const ACTION_SCOPE = '[Filesets]';

export namespace FilesetsActions {
    export class LoadFilesets {
        static readonly type = `${ACTION_SCOPE} List Filesets`
        constructor(public uri: string) { }
    }

    export class Reset {
        static readonly type = `${ACTION_SCOPE} Reset`
        constructor() { }
    }

    export class SelectedFileset{
        static readonly type = `${ACTION_SCOPE} Selected Fileset`
        constructor(public fileset: any) { }
    }

    export class CreateFilesetIntent {
        static readonly type = `${ACTION_SCOPE} Create Fileset Intent`;
        constructor(public payload: any, public uri: string) { }
    }

    export class CreateFilesetIntentSuccess {
        static readonly type = `${ACTION_SCOPE} Create Fileset Intent Success`;
        constructor() { }
    }

    export class CreateFilesetIntentFailed {
        static readonly type = `${ACTION_SCOPE} Create Fileset Intent Failed`;
        constructor() { }
    }

    export class EditFilesetIntent {
        static readonly type = `${ACTION_SCOPE} Edit Fileset Intent`;
        constructor(public payload: any, public uri: string) { }
    }

    export class EditFilesetIntentSuccess {
        static readonly type = `${ACTION_SCOPE} Edit Fileset Intent Success`;
        constructor() { }
    }

    export class EditFilesetIntentFailed {
        static readonly type = `${ACTION_SCOPE} Edit Fileset Intent Failed`;
        constructor() { }
    }

    export class DeleteFilesetIntent {
        static readonly type = `${ACTION_SCOPE} Delete Fileset Intent`;
        constructor(public uri: string) { }
    }

    export class DeleteFilesetIntentSuccess {
        static readonly type = `${ACTION_SCOPE} Delete Fileset Intent Success`;
        constructor() { }
    }

    export class DeleteFilesetIntentFailed {
        static readonly type = `${ACTION_SCOPE} Delete File Intent Failed`;
        constructor() { }
    }

    export class LoadFiles {
        static readonly type = `${ACTION_SCOPE} List Files`
        constructor(public uri: string) { }
    }
}