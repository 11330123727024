import { Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { AuthGuard } from './shared/auth/auth.guard';
import { provideStates } from '@ngxs/store';
import { AuthState } from './store/auth/auth.state';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { UserResolver } from './resolvers/user.resolver';
import { BaseLayoutComponent } from './layouts/base-layout/base-layout.component';
import { LoginOrganizationComponent } from './pages/login/login-organization/login-organization.component';
import { SessionResolver } from './resolvers/session.resolver';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        canActivate: [AuthGuard],
        path: 'login-organizations',
        component: LoginOrganizationComponent,
        providers: [provideStates([AuthState])]
    },
    {
        canActivate: [AuthGuard],
        path: '',
        component: BaseLayoutComponent,
        resolve: {
            user: UserResolver,
            session: SessionResolver
        },
        children: [
            {
                path: 'dashboard',
                component: DashboardComponent,
                data: {
                    title: $localize`Dashboard`
                }
            },
            {
                path: 'accounts',
                loadChildren: () => import('./pages/accounts/routes').then((m) => m.routes)
            },
            {
                path: 'users',
                loadChildren: () => import('./pages/users/routes').then((m) => m.routes),
                resolve: {
                    user: UserResolver,
                    session: SessionResolver
                }
            },
            {
                path: 'organizations',
                loadChildren: () => import('./pages/organizations/routes').then((m) => m.routes)
            },
            {
                path: 'assistants',
                loadChildren: () => import('./pages/assistants/routes').then((m) => m.routes)
            },
            {
                path: 'functions',
                loadChildren: () => import('./pages/functions/routes').then((m) => m.routes)
            },
            {
                path: 'files',
                loadChildren: () => import('./pages/files/routes').then((m) => m.routes)
            },
            {
                path: 'filesets',
                loadChildren: () => import('./pages/filesets/routes').then((m) => m.routes)
            },
            {
                path: 'links',
                loadChildren: () => import('./pages/filebuilders/routes').then((m) => m.routes)
            }
        ]
    }
];
