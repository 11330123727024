<div class="bg-light dark:bg-transparent min-vh-100 d-flex flex-row align-items-center">
  <c-container breakpoint="md">
    <c-row class="justify-content-center">
      <c-col lg="12" xl="8">
        <c-card-group>
          <c-card class="p-4">
            <c-card-body>
              <img src="assets/images/logos/Logo_Horizontal_Light.png" class="img-fluid" width="45%">
              <p class="text-body-secondary">Please, select your organization</p>
              <select aria-label="Large select example" class="form-select" sizing="lg" [(ngModel)]="selectedOrganization" (change)="onSelect()">
                @for (org of organizations; track org.entity.uuid) {
                  <option [ngValue]="org">{{ org.entity.name }}</option>
                }
              </select>
            </c-card-body>
          </c-card>
          <c-card class="text-white bg-primary py-5">
            <c-card-body class="text-center">
              <div>
                <h3>Welcome back!</h3>
                <p>
                  Please, choose your organization. By choosing the appropriate organization, Linkpal can provide you with relevant features, content, and settings that align with your specific needs and role within the company.
                </p>
              </div>
            </c-card-body>
          </c-card>
        </c-card-group>
      </c-col>
    </c-row>
  </c-container>
</div>

