import { Component } from '@angular/core';
import { FooterComponent } from '@coreui/angular';

@Component({
  selector: 'app-footer-layout',
  standalone: true,
  imports: [],
  templateUrl: './footer-layout.component.html',
  styleUrl: './footer-layout.component.scss'
})
export class FooterLayoutComponent extends FooterComponent{
  constructor() {
    super();
  }
}
