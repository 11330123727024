import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    iconComponent: { name: 'cil-speedometer' },
  },
  {
    name: 'Users',
    url: '/users',
    iconComponent: { name: 'cil-people' },
  },
  {
    name: 'Assistants',
    url: '/assistants',
    iconComponent: { name: 'cil-chat-bubble' },
  },
  {
    name: 'Functions',
    url: '/functions',
    iconComponent: { name: 'cil-functions' },
  },
  {
    name: 'Files',
    url: '/files',
    iconComponent: { name: 'cil-copy' },
  },
  {
    name: 'Folders',
    url: '/filesets',
    iconComponent: { name: 'cil-library' },
  },
  {
    name: 'Links',
    url: '/links',
    iconComponent: { name: 'cil-link' },
  }
];
