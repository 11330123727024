const ACTION_SCOPE = '[FileBuilders]';

export namespace FileBuildersActions {
    export class LoadFileBuilders {
        static readonly type = `${ACTION_SCOPE} List FileBuilders`
        constructor(public uri: string) { }
    }

    export class LoadFilesets {
        static readonly type = `${ACTION_SCOPE} List Filesets`
        constructor(public uri: string) { }
    }

    export class CreateFileBuilderIntent {
        static readonly type = `${ACTION_SCOPE} Create FileBuilder Intent`;
        constructor(public payload: any, public uri: string) { }
    }

    export class CreateFileBuilderIntentSuccess {
        static readonly type = `${ACTION_SCOPE} Create FileBuilder Intent Success`;
        constructor() { }
    }

    export class CreateFileBuilderIntentFailed {
        static readonly type = `${ACTION_SCOPE} Create FileBuilder Intent Failed`;
        constructor() { }
    }

    export class SelectedFileBuilder {
        static readonly type = `${ACTION_SCOPE} Selected FileBuilder`
        constructor(public fileBuilder: any) { }
    }

    export class EditFileBuilderIntent {
        static readonly type = `${ACTION_SCOPE} Edit FileBuilder Intent`;
        constructor(public payload: any, public uri: string) { }
    }

    export class EditFileBuilderIntentSuccess {
        static readonly type = `${ACTION_SCOPE} Edit FileBuilder Intent Success`;
        constructor() { }
    }

    export class EditFileBuilderIntentFailed {
        static readonly type = `${ACTION_SCOPE} Edit FileBuilder Intent Failed`;
        constructor() { }
    }

    export class Reset {
        static readonly type = `${ACTION_SCOPE} Reset`
        constructor() { }
    }
}