import { Action, Selector, State, StateContext } from "@ngxs/store";
import { FileBuilder } from "../../models/filebuilder";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FileBuildersActions } from "./filebuilders.actions";
import { catchError, mergeMap, tap, throwError } from "rxjs";
import { Filesets } from "../../models/filesets";

export class FileBuildersStateModel {
  loading: boolean | undefined;
  error: any;
  fileBuilderList: FileBuilder[] | undefined;
  fileBuilderSelected: FileBuilder | undefined | null;
  next: any;
  previous: any;
  filesetList: Filesets[] | undefined;
  nextFilesets: any;
}

const defaults: FileBuildersStateModel = {
  loading: false,
  error: null,
  fileBuilderList: [],
  fileBuilderSelected: null,
  next: null,
  previous: null,
  filesetList: [],
  nextFilesets: null
};

@State<FileBuildersStateModel>({
  name: 'filebuilders',
  defaults,
})
@Injectable()
export class FileBuildersState {
  @Selector()
  static isLoading(state: FileBuildersStateModel) {
    return state.loading;
  }

  @Selector()
  static getFileBuildersError(state: FileBuildersStateModel) {
    return state.error;
  }

  @Selector()
  static getFileBuilderList(state: FileBuildersStateModel) {
    return state.fileBuilderList;
  }

  @Selector()
  static getFileBuilderSelected(state: FileBuildersStateModel) {
    return state.fileBuilderSelected;
  }

  @Selector()
  static getLoadNext(state: FileBuildersStateModel) {
    return state.next;
  }

  @Selector()
  static getLoadPrevious(state: FileBuildersStateModel) {
    return state.previous;
  }

  @Selector()
  static getFilesetList(state: FileBuildersStateModel) {
    return state.filesetList;
  }

  @Selector()
  static getLoadNextFilesets(state: FileBuildersStateModel) {
    return state.nextFilesets;
  }

  constructor(private http: HttpClient) { }

  @Action(FileBuildersActions.LoadFileBuilders)
  loadFileBuilders(
    ctx: StateContext<FileBuildersStateModel>,
    { uri }: FileBuildersActions.LoadFileBuilders
  ) {
    ctx.patchState({ loading: true, error: null });
    return this.http.get(uri).pipe(
      tap((resp: any) => {
        ctx.patchState({
          loading: false,
          fileBuilderList: resp.entities,
          next:
            resp && resp.resources.next
              ? resp.resources.next.uri.split('?')[1]
              : null,
          previous:
            resp && resp.resources.previous
              ? resp.resources.previous.uri.split('?')[1]
              : null,
        });
      }),
      catchError((err) => {
        ctx.patchState({
          loading: false,
          fileBuilderList: [],
          next: null,
          previous: null,
          error: err.error.errors
            ? err.error.errors.toString()
            : err.error.message,
        });
        return throwError(() => err);
      })
    );
  }

  @Action(FileBuildersActions.LoadFilesets)
  loadFilesets(
    ctx: StateContext<FileBuildersStateModel>,
    { uri }: FileBuildersActions.LoadFilesets
  ) {
    ctx.patchState({ loading: true });
    return this.http.get(uri).pipe(
      tap((resp: any) => {
        ctx.patchState({
          loading: false,
          filesetList: resp.entities,
          nextFilesets:
            resp && resp.resources.next
              ? resp.resources.next.uri.split('?')[1]
              : null,
        });
      }),
      catchError((err) => {
        ctx.patchState({ loading: false });
        return throwError(() => err);
      })
    );
  }

  @Action(FileBuildersActions.CreateFileBuilderIntent)
  createFileBuilderIntent(
    ctx: StateContext<FileBuildersStateModel>,
    { payload, uri }: FileBuildersActions.CreateFileBuilderIntent
  ) {
    ctx.patchState({ loading: true, error: null });
    return this.http.post(uri, payload).pipe(
      tap((result: any) => {
        ctx.patchState({ loading: false, error: null });
      }),
      mergeMap(() => {
        return ctx.dispatch(
          new FileBuildersActions.CreateFileBuilderIntentSuccess()
        );
      }),
      catchError((err) => {
        ctx.patchState({
          loading: false,
          error: err.error.errors
            ? err.error.errors.toString()
            : err.error.message,
        });
        return ctx.dispatch(
          new FileBuildersActions.CreateFileBuilderIntentFailed()
        );
      })
    );
  }

  @Action(FileBuildersActions.SelectedFileBuilder)
  selectAssistant(
    ctx: StateContext<FileBuildersStateModel>,
    { fileBuilder }: FileBuildersActions.SelectedFileBuilder
  ) {
    ctx.patchState({ fileBuilderSelected: fileBuilder });
  }

  @Action(FileBuildersActions.EditFileBuilderIntent)
  editAssistantIntent(
    ctx: StateContext<FileBuildersStateModel>,
    { payload, uri }: FileBuildersActions.EditFileBuilderIntent
  ) {
    ctx.patchState({ loading: true, error: null });
    return this.http.put(uri, payload).pipe(
      tap(() => {
        ctx.patchState({ loading: false });
      }),
      mergeMap(() => {
        return ctx.dispatch(new FileBuildersActions.EditFileBuilderIntentSuccess());
      }),
      catchError((err) => {
        ctx.patchState({
          loading: false,
          error: err.error.errors
            ? err.error.errors.toString()
            : err.error.message,
        });
        return ctx.dispatch(new FileBuildersActions.EditFileBuilderIntentFailed());
      })
    );
  }

  @Action(FileBuildersActions.Reset)
  reset(ctx: StateContext<FileBuildersStateModel>, { }: FileBuildersActions.Reset) {
    ctx.patchState({ fileBuilderSelected: null, error: null, filesetList: [], nextFilesets: null });
  }
}