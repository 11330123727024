import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import {APP_SETTINGS} from "./app/shared/utils/app-settings";

fetch('/.well-known/api.json')
  .then((response) => response.json())
  .then((config) => {
    APP_SETTINGS.api = config.api;
    appConfig.providers.push( { provide: APP_SETTINGS, useValue: APP_SETTINGS });
    bootstrapApplication(AppComponent, appConfig)
      .catch((err) => console.error(err));
  });
