import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Organization } from '../../models/organization';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OrganizationsActions } from './organizations.actions';
import { catchError, mergeMap, tap, throwError } from 'rxjs';

export class OrganizationsStateModel {
  loading: boolean | undefined;
  error: any;
  organization: Organization | undefined | null;
  organizationHeader: Organization | undefined | null;
  organizationList: Organization[] | undefined;
  organizationSystem: Organization | undefined | null;
  organizationSystemList: Organization[] | undefined;
  next: any;
  previous: any;
}

const defaults: OrganizationsStateModel = {
  loading: false,
  error: null,
  organization: null,
  organizationHeader: null,
  organizationList: [],
  organizationSystem: null,
  organizationSystemList: [],
  next: null,
  previous: null,
};

@State<OrganizationsStateModel>({
  name: 'organizations',
  defaults,
})
@Injectable()
export class OrganizationsState {
  @Selector()
  static isLoading(state: OrganizationsStateModel) {
    return state.loading;
  }

  @Selector()
  static getOrganizationError(state: OrganizationsStateModel) {
    return state.error;
  }

  @Selector()
  static getSelectedOrganization(state: OrganizationsStateModel) {
    return state.organization;
  }

  @Selector()
  static getOrganizationHeader(state: OrganizationsStateModel) {
    return state.organizationHeader;
  }

  @Selector()
  static getOrganizationList(state: OrganizationsStateModel) {
    return state.organizationList;
  }

  @Selector()
  static getSelectedSystemOrganization(state: OrganizationsStateModel) {
    return state.organizationSystem;
  }

  @Selector()
  static getOrganizationSystemList(state: OrganizationsStateModel) {
    return state.organizationSystemList;
  }

  @Selector()
  static getLoadNext(state: OrganizationsStateModel) {
    return state.next;
  }

  @Selector()
  static getLoadPrevious(state: OrganizationsStateModel) {
    return state.previous;
  }

  constructor(private http: HttpClient) {}

  @Action(OrganizationsActions.LoadOrganizations)
  loadOrganizations(
    ctx: StateContext<OrganizationsStateModel>,
    { uri }: OrganizationsActions.LoadOrganizations
  ) {
    ctx.patchState({ loading: true });
    return this.http.get(uri).pipe(
      tap((resp: any) => {
        ctx.patchState({
          loading: false,
          organizationList: resp.entities,
        });
      }),
      catchError((err) => {
        ctx.patchState({ loading: false, organizationList: [], next: null, previous: null });
        return throwError(() => err);
      })
    );
  }

  @Action(OrganizationsActions.SelectOrganization)
  selectOrganization(
    ctx: StateContext<OrganizationsStateModel>,
    { org }: OrganizationsActions.SelectOrganization
  ) {
    ctx.patchState({ organization: org });
  }

  @Action(OrganizationsActions.SetOrganization)
  setOrganization(
    ctx: StateContext<OrganizationsStateModel>,
    { org }: OrganizationsActions.SetOrganization
  ) {
    ctx.patchState({ organizationHeader: org });
  }

  @Action(OrganizationsActions.LoadSystemOrganizations)
  loadSystemOrganizations(
    ctx: StateContext<OrganizationsStateModel>,
    { uri }: OrganizationsActions.LoadSystemOrganizations
  ) {
    ctx.patchState({ loading: true });
    return this.http.get(uri).pipe(
      tap((resp: any) => {
        ctx.patchState({
          loading: false,
          organizationSystemList: resp.entities,
          next:
            resp && resp.resources.next
              ? resp.resources.next.uri.split('?')[1]
              : null,
          previous:
            resp && resp.resources.previous
              ? resp.resources.previous.uri.split('?')[1]
              : null,
        });
      }),
      catchError((err) => {
        ctx.patchState({ loading: false });
        return throwError(() => err);
      })
    );
  }

  @Action(OrganizationsActions.SelectSystemOrganization)
  selectSystemOrganization(
    ctx: StateContext<OrganizationsStateModel>,
    { org }: OrganizationsActions.SelectOrganization
  ) {
    ctx.patchState({ organizationSystem: org });
  }

  @Action(OrganizationsActions.CreateOrganizationIntent)
  createOrganizationIntent(
    ctx: StateContext<OrganizationsStateModel>,
    { payload, uri }: OrganizationsActions.CreateOrganizationIntent
  ) {
    ctx.patchState({ loading: true, error: null });
    return this.http.post(uri, payload).pipe(
      tap(() => {
        ctx.patchState({ loading: false });
      }),
      mergeMap(() => {
        return ctx.dispatch(new OrganizationsActions.CreateOrganizationIntentSuccess());
      }),
      catchError((err) => {
        ctx.patchState({
          loading: false,
          error: err.error.errors
            ? err.error.errors.toString()
            : err.error.message,
        });
        return ctx.dispatch(new OrganizationsActions.CreateOrganizationIntentFailed());
      })
    );
  }

  @Action(OrganizationsActions.EditOrganizationIntent)
  editAccountIntent(
    ctx: StateContext<OrganizationsStateModel>,
    { payload, uri }: OrganizationsActions.EditOrganizationIntent
  ) {
    ctx.patchState({ loading: true, error: null });
    return this.http.put(uri, payload).pipe(
      tap(() => {
        ctx.patchState({ loading: false });
      }),
      mergeMap(() => {
        return ctx.dispatch(new OrganizationsActions.EditOrganizationIntentSuccess());
      }),
      catchError((err) => {
        ctx.patchState({
          loading: false,
          error: err.error.errors
            ? err.error.errors.toString()
            : err.error.message,
        });
        return ctx.dispatch(new OrganizationsActions.EditOrganizationIntentFailed());
      })
    );
  }

  @Action(OrganizationsActions.DeleteOrganizationIntent)
  deleteOrganizationIntent(
    ctx: StateContext<OrganizationsStateModel>,
    { uri }: OrganizationsActions.DeleteOrganizationIntent
  ) {
    ctx.patchState({ loading: true, error: null });
    return this.http.delete(uri).pipe(
      tap(() => {
        ctx.patchState({ loading: false });
        return ctx.dispatch(
          new OrganizationsActions.DeleteOrganizationIntentSuccess()
        );
      }),
      catchError((err) => {
        ctx.patchState({
          loading: false,
          error: err.error.errors
            ? err.error.errors.toString()
            : err.error.message,
        });
        return ctx.dispatch(
          new OrganizationsActions.DeleteOrganizationIntentFailed()
        );
      })
    );
  }

}
