import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, withEnabledBlockingInitialNavigation, withHashLocation, withInMemoryScrolling, withRouterConfig, withViewTransitions } from '@angular/router';
import { provideStore } from '@ngxs/store';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { withNgxsReduxDevtoolsPlugin } from '@ngxs/devtools-plugin';
import { AuthState } from './store/auth/auth.state';
import { SESSION_STORAGE_ENGINE, withNgxsStoragePlugin } from '@ngxs/storage-plugin';
import { AuthInterceptor } from './shared/auth/auth.interceptor';
import { OrganizationsState } from './store/organizations/organizations.state';
import { DropdownModule, SidebarModule } from '@coreui/angular';
import { IconSetService } from '@coreui/icons-angular';
import { UsersState } from './store/users/users.state';
import { AppState } from './store/app/app.state';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { AssistantsState } from './store/assistants/assistants.state';
import { provideToastr } from 'ngx-toastr';
import { provideAnimations } from '@angular/platform-browser/animations';
import { FunctionsState } from './store/functions/functions.state';
import { FilesState } from './store/files/files.state';
import { FilesetsState } from './store/filesets/filesets.state';
import { AccountsState } from './store/account/accounts.state';
import { FileUtils } from './shared/utils/file.utils';
import {APP_SETTINGS} from "./shared/utils/app-settings";
import { FileBuildersState } from './store/filebuilders/filebuilders.state';

export const appConfig: ApplicationConfig = {
  providers: [
    provideStore([AuthState, OrganizationsState, UsersState, AppState, AssistantsState,
      FunctionsState, FilesState, FilesetsState, AccountsState, FileBuildersState], withNgxsStoragePlugin({
      keys: [
        {
          key: 'auth',
          engine: SESSION_STORAGE_ENGINE
        },
        {
          key: 'app',
          engine: SESSION_STORAGE_ENGINE
        },
        {
          key: 'organizations',
          engine: SESSION_STORAGE_ENGINE
        },
        {
          key: 'assistants',
          engine: SESSION_STORAGE_ENGINE
        },
        {
          key: 'functions',
          engine: SESSION_STORAGE_ENGINE
        },
        {
          key: 'files',
          engine: SESSION_STORAGE_ENGINE
        },
        {
          key: 'filesets',
          engine: SESSION_STORAGE_ENGINE
        },
        {
          key: 'accounts',
          engine: SESSION_STORAGE_ENGINE
        },
        {
          key: 'filebuilders',
          engine: SESSION_STORAGE_ENGINE
        }
      ]
    }), withNgxsReduxDevtoolsPlugin()),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes, withRouterConfig({
      onSameUrlNavigation: 'reload'
    }), withInMemoryScrolling({
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled'
    }),
      withEnabledBlockingInitialNavigation(),
      withViewTransitions(),
      withHashLocation()),
    importProvidersFrom(SidebarModule, DropdownModule),
    importProvidersFrom(ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger', // set defaults here
    })),
    IconSetService,
    FileUtils,
    provideAnimationsAsync(),
    provideToastr({
      extendedTimeOut: 3000,
      positionClass: 'toast-top-full-width',
      tapToDismiss: true,
      closeButton: true,
      preventDuplicates: true}),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ]
};

