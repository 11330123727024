import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Account } from '../../models/account';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AccountsActions } from './accounts.actions';
import { catchError, mergeMap, tap, throwError } from 'rxjs';

export class AccountsStateModel {
  loading: boolean | undefined;
  error: any;
  accountList: Account[] | undefined;
  accountSelected: Account | undefined | null;
  next: any;
  previous: any;
}

const defaults: AccountsStateModel = {
  loading: false,
  error: null,
  accountList: [],
  accountSelected: null,
  next: null,
  previous: null,
};

@State<AccountsStateModel>({
  name: 'accounts',
  defaults,
})
@Injectable()
export class AccountsState {
  @Selector()
  static isLoading(state: AccountsStateModel) {
    return state.loading;
  }

  @Selector()
  static getAccountsError(state: AccountsStateModel) {
    return state.error;
  }

  @Selector()
  static getAccountList(state: AccountsStateModel) {
    return state.accountList;
  }

  @Selector()
  static getLoadNext(state: AccountsStateModel) {
    return state.next;
  }

  @Selector()
  static getLoadPrevious(state: AccountsStateModel) {
    return state.previous;
  }

  @Selector()
  static getAccountSelected(state: AccountsStateModel) {
    return state.accountSelected;
  }

  constructor(private http: HttpClient) {}

  @Action(AccountsActions.LoadAccounts)
  loadAccounts(
    ctx: StateContext<AccountsStateModel>,
    { uri }: AccountsActions.LoadAccounts
  ) {
    ctx.patchState({ loading: true });
    return this.http.get(uri).pipe(
      tap((resp: any) => {
        ctx.patchState({
          loading: false,
          accountList: resp.entities,
          next:
            resp && resp.resources.next
              ? resp.resources.next.uri.split('?')[1]
              : null,
          previous:
            resp && resp.resources.previous
              ? resp.resources.previous.uri.split('?')[1]
              : null,
        });
      }),
      catchError((err) => {
        ctx.patchState({ loading: false, accountList: [], next: null, previous: null });
        return throwError(() => err);
      })
    );
  }

  @Action(AccountsActions.SelectedAccount)
  selectAccount(
    ctx: StateContext<AccountsStateModel>,
    { account }: AccountsActions.SelectedAccount
  ) {
    ctx.patchState({ accountSelected: account });
  }

  @Action(AccountsActions.CreateAccountIntent)
  createAccountIntent(
    ctx: StateContext<AccountsStateModel>,
    { payload, uri }: AccountsActions.CreateAccountIntent
  ) {
    ctx.patchState({ loading: true, error: null });
    return this.http.post(uri, payload).pipe(
      tap(() => {
        ctx.patchState({ loading: false });
      }),
      mergeMap(() => {
        return ctx.dispatch(new AccountsActions.CreateAccountIntentSuccess());
      }),
      catchError((err) => {
        ctx.patchState({
          loading: false,
          error: err.error.errors
            ? err.error.errors.toString()
            : err.error.message,
        });
        return ctx.dispatch(new AccountsActions.CreateAccountIntentFailed());
      })
    );
  }

  @Action(AccountsActions.EditAccountIntent)
  editAccountIntent(
    ctx: StateContext<AccountsStateModel>,
    { payload, uri }: AccountsActions.EditAccountIntent
  ) {
    ctx.patchState({ loading: true, error: null });
    return this.http.put(uri, payload).pipe(
      tap(() => {
        ctx.patchState({ loading: false });
      }),
      mergeMap(() => {
        return ctx.dispatch(new AccountsActions.EditAccountIntentSuccess());
      }),
      catchError((err) => {
        ctx.patchState({
          loading: false,
          error: err.error.errors
            ? err.error.errors.toString()
            : err.error.message,
        });
        return ctx.dispatch(new AccountsActions.EditAccountIntentFailed());
      })
    );
  }

  @Action(AccountsActions.DeleteAccountIntent)
  deleteAssistantIntent(
    ctx: StateContext<AccountsStateModel>,
    { uri }: AccountsActions.DeleteAccountIntent
  ) {
    ctx.patchState({ loading: true, error: null });
    return this.http.delete(uri).pipe(
      tap(() => {
        ctx.patchState({ loading: false });
        return ctx.dispatch(new AccountsActions.DeleteAccountIntentSuccess());
      }),
      catchError((err) => {
        ctx.patchState({
          loading: false,
          error: err.error.errors
            ? err.error.errors.toString()
            : err.error.message,
        });
        return ctx.dispatch(new AccountsActions.DeleteAccountIntentFailed());
      })
    );
  }
}
