@if (message) {
  <c-alert [dismissible]="dismissible" color={{msgType}}>{{message}}</c-alert>
}

<div class="bg-light dark:bg-transparent min-vh-100 d-flex flex-row align-items-center">
    <c-container breakpoint="md">
      <c-row class="justify-content-center">
        <c-col lg="10" xl="8">
          <c-card-group>
            <c-card class="p-4">
              <c-card-body>
                <form cForm [formGroup]="loginFormGroup" enctype="multipart/form-data" (ngSubmit)="onSignIn()">
                  <img src="assets/images/logos/Logo_Horizontal_Light.png" class="img-fluid" width="45%">
                  <p class="text-body-secondary">Sign In to your account</p>
                  <c-input-group class="mb-4">
                    <span cInputGroupText>
                      <svg cIcon name="cibMailRu"></svg>
                    </span>
                    <input autoComplete="email" type="email" cFormControl placeholder="" formControlName="email"
                    (blur)="updateEmailErrorMessage()" required/>
                  </c-input-group>
                  @if (loginFormGroup.controls.email.invalid) {
                    <p style="color: red;">{{errorEmailMessage()}}</p>
                    }
                  <c-input-group class="mb-4">
                    <span cInputGroupText>
                      <svg cIcon name="cilLockLocked"></svg>
                    </span>
                    <input
                      autoComplete="current-password"
                      cFormControl
                      placeholder=""
                      type="password"
                      formControlName="password" (blur)="updatePasswordErrorMessage()" required
                    />
                  </c-input-group>
                  @if (loginFormGroup.controls.password.invalid) {
                    <p style="color: red;">{{errorPasswordMessage()}}</p>
                    }
                  <c-row>
                    <c-col xs="6">
                      <button cButton class="px-4" color="primary" type="submit" [disabled]="!loginFormGroup.valid">
                        Login
                      </button>
                    </c-col>
                    <c-col class="text-right" xs="6">
                      <button cButton class="px-0" color="link">
                        Forgot password?
                      </button>
                    </c-col>
                  </c-row>
                </form>
              </c-card-body>
            </c-card>
            <c-card [ngStyle]="{'minWidth.%': 44}" class="text-white bg-primary py-5">
              <c-card-body class="text-center">
                <div>
                  <h2>Sign Up</h2>
                  <p>
                    Create your account and start using Linkpal to seamlessly connect your resources to powerful language models. Join our community and unlock the full potential of your data.
                  </p>
                  <button [active]="true" cButton class="mt-3" color="primary" routerLink="/register">
                    Register Now!
                  </button>
                </div>
              </c-card-body>
            </c-card>
          </c-card-group>
        </c-col>
      </c-row>
    </c-container>
  </div>
