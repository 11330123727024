export const ApiPaths = {
  Login: '/login',
  Logout: '/me/logout',
  GetUserLogin: '/me',
  LoadOrganizations: '/me/organizations',
  Users:  '/users',
  Assistants: '/handlers',
  AssistantConfiguration: '/configuration',
  AssistantConfigurationFiles: '/configuration/files',
  AssistantFunctions: '/functions',
  AssistantSync: '/sync',
  AssistantTemplates: '/templates/handlers',
  Functions: '/functions',
  Files: '/files',
  Filesets: '/filesets',
  FileBuilders: '/filebuilders',
  System: '/system',
  Accounts: '/system/accounts',
  Organizations: '/system/organizations',
  SentInvitation: '/system/invitations',
}


