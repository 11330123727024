const ACTION_SCOPE = '[Files]';

export namespace FilesActions {
    export class LoadFiles {
        static readonly type = `${ACTION_SCOPE} List Files`
        constructor(public uri: string) { }
    }

    export class Reset {
        static readonly type = `${ACTION_SCOPE} Reset`
        constructor() { }
    }

    export class SelectedFile{
        static readonly type = `${ACTION_SCOPE} Selected File`
        constructor(public file: any) { }
    }

    export class CreateFileIntent {
        static readonly type = `${ACTION_SCOPE} Create File Intent`;
        constructor(public payload: any, public uri: string) { }
    }

    export class CreateFileIntentSuccess {
        static readonly type = `${ACTION_SCOPE} Create File Intent Success`;
        constructor() { }
    }

    export class CreateFileIntentFailed {
        static readonly type = `${ACTION_SCOPE} Create File Intent Failed`;
        constructor() { }
    }

    export class EditFileIntent {
        static readonly type = `${ACTION_SCOPE} Edit File Intent`;
        constructor(public payload: any, public uri: string) { }
    }

    export class EditFileIntentSuccess {
        static readonly type = `${ACTION_SCOPE} Edit File Intent Success`;
        constructor() { }
    }

    export class EditFileIntentFailed {
        static readonly type = `${ACTION_SCOPE} Edit File Intent Failed`;
        constructor() { }
    }

    export class DeleteFileIntent {
        static readonly type = `${ACTION_SCOPE} Delete File Intent`;
        constructor(public uri: string) { }
    }

    export class DeleteFileIntentSuccess {
        static readonly type = `${ACTION_SCOPE} Delete File Intent Success`;
        constructor() { }
    }

    export class DeleteFileIntentFailed {
        static readonly type = `${ACTION_SCOPE} Delete File Intent Failed`;
        constructor() { }
    }

    export class DownloadFile {
        static readonly type = `${ACTION_SCOPE} Download File`;
        constructor(public uri: string) { }
    }

    export class DownloadFileSuccess {
        static readonly type = `${ACTION_SCOPE} Download File Success`;
        constructor() { }
    }

    export class DownloadFileFailed {
        static readonly type = `${ACTION_SCOPE} Download File Failed`;
        constructor() { }
    }
}