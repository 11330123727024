import { Component, OnInit } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { NgScrollbar } from 'ngx-scrollbar';
import {
  ContainerComponent,
  ShadowOnScrollDirective,
  SidebarBrandComponent,
  SidebarComponent,
  SidebarFooterComponent,
  SidebarHeaderComponent,
  SidebarNavComponent,
  SidebarToggleDirective,
  SidebarTogglerDirective
} from '@coreui/angular';
import { HeaderLayoutComponent } from "../header-layout/header-layout.component";
import { navItems } from './_nav';
import { navItemsRoot } from './_nav-root';
import { FooterLayoutComponent } from "../footer-layout/footer-layout.component";
import { Store } from '@ngxs/store';
import { AuthState } from '../../store/auth/auth.state';
import { SessionToken } from '../../models/session-token';

function isOverflown(element: HTMLElement) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    SidebarComponent,
    SidebarHeaderComponent,
    SidebarBrandComponent,
    RouterLink,
    NgScrollbar,
    SidebarNavComponent,
    SidebarFooterComponent,
    SidebarToggleDirective,
    SidebarTogglerDirective,
    ShadowOnScrollDirective,
    ContainerComponent,
    RouterOutlet,
    HeaderLayoutComponent,
    FooterLayoutComponent
  ],
  templateUrl: './base-layout.component.html',
  styleUrl: './base-layout.component.scss'
})
export class BaseLayoutComponent implements OnInit {
  public navItems: any[] = [];
  public navItemsRoot: any[] = [];
  public sessionToken: SessionToken | undefined;
  public isShow: boolean | undefined = false;

  constructor(private store: Store) {
    this.sessionToken = this.store.selectSnapshot<any>(AuthState.getSession);
    this.isShow = this.sessionToken?.entity.isSuperUser
  }

  ngOnInit(): void {
    this.navItems = navItems;
    
    if (this.sessionToken?.entity.isSuperUser) {
      this.navItemsRoot = navItemsRoot;

    }
  }

  onScrollbarUpdate($event: any) {
  }
}
