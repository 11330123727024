export let MSG: any = {
  /* Account */
  accountCreatedSuccess: 'Account created successfully!',
  accountEditSuccess: 'Account updated successfully!',
  accountDeletedSuccess: 'Account deleted successfully!',

  /* Organization */
  organizationCreatedSuccess: 'Organization created successfully!',
  organizationEditSuccess: 'Organization updated successfully!',
  organizationDeletedSuccess: 'Organization deleted successfully!',

  /* Users */
  userCreatedSuccess: 'User created successfully!',
  userEditSuccess: 'User updated successfully!',
  userDeletedSuccess: 'User deleted successfully!',
  emptyAccountIdOrEmail: 'Please select an Account or enter an Email Address!',
  userSentInvitationSuccess: 'Sent Invitation successfully!',

  /* Assistant */
  assistantCreatedSuccess: 'Assistant created successfully!',
  assistantEditSuccess: 'Assistant updated successfully!',
  assistantDeletedSuccess: 'Assistant deleted successfully!',
  assistantEditConfigurationSuccess: 'Assistant - Configuration updated successfully!',
  assistantSelectFilesEmpty: 'Select any file please.',
  assistantSelectFunctionEmpty: 'Select any function please.',
  assistantAddFunctionSuccess: 'Function added successfully!',
  assistantPublishSuccess: 'Sent to Publish successfully!',

  /* Functions */
  functionCreatedSuccess: 'Function created successfully!',
  functionEditSuccess: 'Function updated successfully!',
  functionDeletedSuccess: 'Function deleted successfully!',

  /* Files */
  fileCreatedSuccess: 'File created successfully!',
  fileEditSuccess: 'File updated successfully!',
  invalidFile: 'The size of the file should not exceed 1 MB',
  emptyFile: 'Please select a file',
  fileDeletedSuccess: 'File deleted successfully!',
  fileDownloadSuccess: 'File download successfully!',

  /* Filesets */
  filesetCreatedSuccess: 'Folder created successfully!',
  filesetEditSuccess: 'Folder updated successfully!',
  filesetDeletedSuccess: 'Folder deleted successfully!',

  /* FileBuilders */
  fileBuilderCreatedSuccess: 'Link created successfully!',
  fileBuilderEditSuccess: 'Link updated successfully!',
  fileBuilderDeletedSuccess: 'Link deleted successfully!',

  /* Session */
  sessionTimeOut: 'Your session has expired, please login again'
};