import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { AuthActions } from "../auth/auth.actions";
import { AppActions } from "./app.actions";
import { SystemData } from "../../models/system";
import { HttpClient } from "@angular/common/http";
import { catchError, tap, throwError } from "rxjs";

export class AppStateModel {
    loading: boolean | undefined;
    loggedOut: boolean | undefined;
    systemData: SystemData | undefined | null;
}

const defaults: AppStateModel = {
    loading: false,
    loggedOut: false,
    systemData: null,
}

@State<AppStateModel>({
    name: 'app',
    defaults
})
@Injectable()
export class AppState {

    @Selector()
    static isLoading(state: AppStateModel) {
        return state.loading;
    }

    @Selector()
    static getLogoutStatus(state: AppStateModel) {
        return state.loggedOut;
    }

    @Selector()
    static getSystemData(state: AppStateModel) {
        return state.systemData;
    }

    constructor(private http: HttpClient) { }

    @Action(AuthActions.LogoutUser)
    logout(ctx: StateContext<AppStateModel>) {
        ctx.patchState({ loggedOut: true });
    }

    @Action(AppActions.DisableLogoutMessage)
    disableLogoutMessage(ctx: StateContext<AppStateModel>) {
        ctx.patchState({ loggedOut: false });
    }

    @Action(AppActions.LoadSystemData)
    loadSystemData(
        ctx: StateContext<AppStateModel>,
        { uri }: AppActions.LoadSystemData
    ) {
        ctx.patchState({ loading: true });
        return this.http.get(uri).pipe(
            tap((resp: any) => {
                ctx.patchState({
                    loading: false,
                    systemData: resp,
                });
            }),
            catchError((err) => {
                ctx.patchState({ loading: false, systemData: null });
                return throwError(() => err);
            })
        );
    }

}