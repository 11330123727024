<ng-container>
  <c-container [fluid]="true" class="border-bottom px-4">
    <button
      [cSidebarToggle]="sidebarId"
      cHeaderToggler
      class="btn"
      toggle="visible"
      style="margin-inline-start: -14px"
      aria-label="Toggle sidebar navigation"
    >
      <svg cIcon name="cilMenu" size="lg"></svg>
    </button>

    <c-header-nav class="d-none d-md-flex ms-auto">
      <form
            class="form-horizontal"
            cForm
            [formGroup]="formGroup"
            style="width: 250px;"
          >
          <div class="row">
            <label class="col-md-4 col-form-label">Organization: </label>
            <div class="col-md-8">
              <select
                aria-label="Large select example"
                class="form-select"
                sizing="lg"
                formControlName="selectedOrganization"
                (change)="onSelect(formGroup.value)"
              >
                @for (org of organizations; track org.entity.uuid) {
                <option value="{{ org.entity.uuid }}">{{ org.entity.name }}</option>
                }
              </select>
            </div>
          </div>
      </form>

    </c-header-nav>

    <c-header-nav class="mx-0">
      <ng-container *ngTemplateOutlet="userDropdown" />
    </c-header-nav>
  </c-container>
  <c-container [fluid]="true" class="px-4 breadcrumb-bkg">
    <c-breadcrumb-router/>
  </c-container>
</ng-container>
<!--</c-header>-->

<ng-template #userDropdown>
  <c-dropdown
    [popperOptions]="{ placement: 'bottom-start' }"
    variant="nav-item"
  >
    <button
      [caret]="false"
      cDropdownToggle
      class="py-0 pe-0"
      aria-label="Open user menu"
    >
      <c-avatar
        shape="rounded-1"
        [size]="'md'"
        src="../../../assets/images/avatars/2.jpg"
        status="success"
        textColor="primary"
        alt="User avatar"
      />
    </button>
    <ul cDropdownMenu class="pt-0 w-auto">
      <!--<li>
        <h6
          cDropdownHeader
          class="bg-body-secondary text-body-secondary fw-semibold py-2 rounded-top"
        >
          Account
        </h6>
      </li>
      <li>
        <a cDropdownItem routerLink="">
          <svg cIcon class="me-2" name="cilBell"></svg>
          Updates
          <c-badge class="ms-2 float-end" color="info"> 42</c-badge>
        </a>
      </li>
      <li>
        <a cDropdownItem routerLink="/apps/email/inbox">
          <svg cIcon class="me-2" name="cilEnvelopeOpen"></svg>
          Messages
          <c-badge class="ms-2 float-end" color="success"> 42 </c-badge>
        </a>
      </li>
      <li>
        <a cDropdownItem routerLink="">
          <svg cIcon class="me-2" name="cilTask"></svg>
          Tasks
          <c-badge class="ms-2 float-end" color="danger"> 42</c-badge>
        </a>
      </li>
      <li>
        <a cDropdownItem routerLink="">
          <svg cIcon class="me-2" name="cilCommentSquare"></svg>
          Comments
          <c-badge class="ms-auto" color="warning"> 42</c-badge>
        </a>
      </li>-->
      <li>
        <h6
          cDropdownHeader
          class="bg-body-secondary text-body-secondary fw-semibold py-2"
        >
          Settings
        </h6>
      </li>
      <li></li>
      <li>
        <a cDropdownItem routerLink="">
          <svg cIcon class="me-2" name="cilUser"></svg>
          Profile
        </a>
      </li>
      <!--<li>
        <a cDropdownItem routerLink="">
          <svg cIcon class="me-2" name="cilSettings"></svg>
          Settings
        </a>
      </li>
      <li>
        <a cDropdownItem routerLink="">
          <svg cIcon class="me-2" name="cilCreditCard"></svg>
          Payments
          <c-badge class="ms-2 float-end" color="secondary"> 42 </c-badge>
        </a>
      </li>
      <li>
        <a cDropdownItem routerLink="">
          <svg cIcon class="me-2" name="cilFile"></svg>
          Projects
          <c-badge class="ms-2 float-end" color="primary"> 42 </c-badge>
        </a>
      </li>-->
      <li>
        <hr cDropdownDivider />
      </li>
      <!--<li>
        <a cDropdownItem routerLink="">
          <svg cIcon class="me-2" name="cilLockLocked"></svg>
          Lock Account
        </a>
      </li>-->
      <li>
        <a cDropdownItem (click)="onLogout()" routerLink="">
          <svg cIcon class="me-2" name="cilAccountLogout"></svg>
          Logout
        </a>
      </li>
    </ul>
  </c-dropdown>
</ng-template>

