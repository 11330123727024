import { Organization } from '../../models/organization';

const ACTION_SCOPE = '[Organizations]';

export namespace OrganizationsActions {
  export class LoadOrganizations {
    static readonly type = `${ACTION_SCOPE} List Organization`;
    constructor(public uri: string) {}
  }

  export class SelectOrganization {
    static readonly type = `${ACTION_SCOPE} Select Organization`;
    constructor(public org: any) {}
  }

  export class SetOrganization {
    static readonly type = `${ACTION_SCOPE} Set Organization`;
    constructor(public org: any) {}
  }

  export class LoadSystemOrganizations {
    static readonly type = `${ACTION_SCOPE} List System Organization`;
    constructor(public uri: string) {}
  }

  export class SelectSystemOrganization {
    static readonly type = `${ACTION_SCOPE} Select System Organization`;
    constructor(public org: any) {}
  }

  export class CreateOrganizationIntent {
    static readonly type = `${ACTION_SCOPE} Create Organization Intent`;
    constructor(public payload: any, public uri: string) {}
  }

  export class CreateOrganizationIntentSuccess {
    static readonly type = `${ACTION_SCOPE} Create Organization Intent Success`;
    constructor() {}
  }

  export class CreateOrganizationIntentFailed {
    static readonly type = `${ACTION_SCOPE} Create Organization Intent Failed`;
    constructor() {}
  }

  export class EditOrganizationIntent {
    static readonly type = `${ACTION_SCOPE} Edit Organization Intent`;
    constructor(public payload: any, public uri: string) {}
  }

  export class EditOrganizationIntentSuccess {
    static readonly type = `${ACTION_SCOPE} Edit Organization Intent Success`;
    constructor() {}
  }

  export class EditOrganizationIntentFailed {
    static readonly type = `${ACTION_SCOPE} Edit Organization Intent Failed`;
    constructor() {}
  }

  export class DeleteOrganizationIntent {
    static readonly type = `${ACTION_SCOPE} Delete Organization Intent`;
    constructor(public uri: string) {}
  }

  export class DeleteOrganizationIntentSuccess {
    static readonly type = `${ACTION_SCOPE} Delete Organization Intent Success`;
    constructor() {}
  }

  export class DeleteOrganizationIntentFailed {
    static readonly type = `${ACTION_SCOPE} Delete Organization Intent Failed`;
    constructor() {}
  }
}
