const ACTION_SCOPE = '[Functions]';

export namespace FunctionsActions {
    export class LoadFunctions {
        static readonly type = `${ACTION_SCOPE} List Functions`
        constructor(public uri: string) { }
    }

    export class Reset {
        static readonly type = `${ACTION_SCOPE} Reset`
        constructor() { }
    }

    export class ResetDefault {
        static type = `${ACTION_SCOPE} Reset Default Values`;
        constructor() { }
      }

    export class SelectedFunction {
        static readonly type = `${ACTION_SCOPE} Selected Function`
        constructor(public funct: any) { }
    }

    export class CreateFunctionIntent {
        static readonly type = `${ACTION_SCOPE} Create Function Intent`;
        constructor(public payload: any, public uri: string) { }
    }

    export class CreateFunctionIntentSuccess {
        static readonly type = `${ACTION_SCOPE} Create Function Intent Success`;
        constructor() { }
    }

    export class CreateFunctionIntentFailed {
        static readonly type = `${ACTION_SCOPE} Create Function Intent Failed`;
        constructor() { }
    }

    export class EditFunctionIntent {
        static readonly type = `${ACTION_SCOPE} Edit Function Intent`;
        constructor(public payload: any, public uri: string) { }
    }

    export class EditFunctionIntentSuccess {
        static readonly type = `${ACTION_SCOPE} Edit Function Intent Success`;
        constructor() { }
    }

    export class EditFunctionIntentFailed {
        static readonly type = `${ACTION_SCOPE} Edit Function Intent Failed`;
        constructor() { }
    }

    export class DeleteFunctionIntent {
        static readonly type = `${ACTION_SCOPE} Delete Function Intent`;
        constructor(public uri: string) { }
    }

    export class DeleteFunctionIntentSuccess {
        static readonly type = `${ACTION_SCOPE} Delete Function Intent Success`;
        constructor() { }
    }

    export class DeleteFunctionIntentFailed {
        static readonly type = `${ACTION_SCOPE} Delete Function Intent Failed`;
        constructor() { }
    }
}