<!--sidebar-->
<c-sidebar #sidebar1="cSidebar" class="d-print-none sidebar sidebar-fixed border-end" colorScheme="dark" id="sidebar1"
  visible>
  <c-sidebar-header class="border-bottom">
    <c-sidebar-brand [routerLink]="[]">
      <img src="assets/images/logos/Logo_Horizontal_Dark.png" alt="Linkpal Logo" class="sidebar-brand-full" height="32">
      <img src="assets/images/logos/Logo_Horizontal_Dark.png" alt="Linkpal Logo" class="sidebar-brand-narrow"
        height="32">
    </c-sidebar-brand>
  </c-sidebar-header>
  @if (isShow) {
  <ng-scrollbar #scrollbar="ngScrollbar" (updated)="onScrollbarUpdate(scrollbar.state)"
    class="overflow border-bottom" pointerEventsMethod="scrollbar" visibility="hover">
    <c-sidebar-nav #overflow [navItems]="navItemsRoot" dropdownMode="close" compact />
  </ng-scrollbar>
  }
  <ng-scrollbar #scrollbar="ngScrollbar" (updated)="onScrollbarUpdate(scrollbar.state)" class="overflow"
    pointerEventsMethod="scrollbar" visibility="hover">
    <c-sidebar-nav #overflow [navItems]="navItems" dropdownMode="close" compact />
  </ng-scrollbar>
  @if (!sidebar1.narrow) {
  <c-sidebar-footer cSidebarToggle="sidebar1" class="border-top d-none d-lg-flex" toggle="unfoldable"
    style="cursor: pointer;">
    <button cSidebarToggler aria-label="Toggle sidebar fold"></button>
  </c-sidebar-footer>
  }
</c-sidebar>

<!--main-->
<div class="wrapper d-flex flex-column min-vh-100">
  <!--app-header-->
  <app-header-layout [cShadowOnScroll]="'sm'" class="mb-4 d-print-none header header-sticky p-0 shadow-sm"
    position="sticky" sidebarId="sidebar1" />

  <!--app-body-->
  <div class="body flex-grow-1">
    <c-container breakpoint="lg" class="h-auto px-4">
      <router-outlet />
    </c-container>
  </div>

  <!--app footer-->
  <app-footer-layout />
</div>