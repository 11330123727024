import { Component, HostListener } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { IconSetService } from '@coreui/icons-angular';
import { iconSubset } from './icons/icon-subset';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'linkpal-admin';

  @HostListener("window:onbeforeunload", ["$event"])
  clearLocalStorage() {
    sessionStorage.clear();
  }

  constructor(
    private iconSetService: IconSetService
  ) {
    // iconSet singleton
    this.iconSetService.icons = { ...iconSubset };
  }
}
