import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { Observable, of } from "rxjs";
import { AuthState } from "../store/auth/auth.state";

@Injectable({ providedIn: 'root' })
export class UserResolver {
    constructor(private store: Store) {}

    resolve(): Observable<any> {
        return of(this.store.selectSnapshot<any>(AuthState.getUserLogged));
    }
}